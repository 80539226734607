@import '../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import '../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '../node_modules/@fortawesome/fontawesome-free/scss/brands.scss';

@import "../resources/styles/variables.scss";
@import "../resources/styles/kendoui-fiba-theme";
@import "../resources/styles/custom-bootstrap";
@import "../resources/styles/global";

/* You can add global styles to this file, and also import other style files */

.k-radio:checked, .k-radio.k-checked {
    border-color: #b89d54;
    color: #ffffff;
    background-color: #b89d54;
}

.k-button-primary, .k-button.k-primary {
    border-color: #b89d54;
    color: #ffffff;
    background-color: #b89d54;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.04));
}

.k-radio:checked:focus, .k-radio.k-checked.k-state-focus {
    border-color: #b89d54;
    box-shadow: 0 0 0 2px rgba(255, 99, 88, 0.3);
}

.k-dialog-titlebar {
    border-color: inherit;
    color: #ffffff;
    background-color: #b89d54;
}

.k-button-primary:hover, .k-button.k-primary:hover, .k-button-primary.k-state-hover, .k-state-hover.k-button.k-primary {
    border-color: #b89d54;
    background-color: #b89d54;
}

.k-button-primary:focus, .k-button.k-primary:focus, .k-button-primary.k-state-focus, .k-state-focus.k-button.k-primary, .k-button-primary.k-state-focused, .k-state-focused.k-button.k-primary {
    box-shadow: 0 0 0 2px rgba(184, 157, 84, 0.3);
}

.k-pager-numbers .k-link.k-state-selected {
    color: #b89d54;
    background-color: rgba(184, 157, 84, 0.2);
}

.k-pager-numbers .k-link {
    color: #b89d54;
}

.k-grid td.k-state-selected, .k-grid tr.k-state-selected > td {
    background-color: rgba(184, 157, 84, 0.25);
}

.k-checkbox:checked, .k-checkbox.k-checked {
    border-color: #b89d54;
    color: #ffffff;
    background-color: #b89d54;
}


.k-list .k-item.k-state-selected, .k-list .k-item.k-state-selected, .k-list-optionlabel.k-state-selected, .k-list-optionlabel.k-state-selected {
    color: #ffffff;
    background-color: #b89d54;
}

.k-list .k-item:hover.k-state-selected, .k-list .k-item.k-state-hover.k-state-selected, .k-list-optionlabel:hover.k-state-selected, .k-list-optionlabel.k-state-hover.k-state-selected {
    color: #ffffff;
    background-color: #b89d54;
}
