@use "@progress/kendo-theme-default/scss/switch/_layout.scss" as *;

@mixin kendo-switch--layout() {

    @include kendo-switch--layout-base();

    // Switch
    .k-switch.k-disabled {
        opacity: 1;
        filter: none;
    }

}
