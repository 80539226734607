@use "../core/_index.scss" as *;

/// The minimum height of the 'No Data' text container.
/// @group no-data
$kendo-no-data-min-height: 140px !default;

/// The color of the 'No Data' text.
/// @group no-data
$kendo-no-data-text: if($kendo-enable-color-system, k-color( subtle ) , $kendo-subtle-text) !default;


@forward "@progress/kendo-theme-default/scss/no-data/_variables.scss" with (
    $kendo-no-data-min-height: $kendo-no-data-min-height,
    $kendo-no-data-text: $kendo-no-data-text
);
