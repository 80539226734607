@use "../core/_index.scss" as *;
@use "@progress/kendo-theme-default/scss/menu/_layout.scss" as *;

@mixin kendo-menu--layout() {

    @include kendo-menu--layout-base();

    .k-menu.k-menu-horizontal:not(.k-context-menu) {
        padding-block: k-spacing(2);
    }
    .k-menu.k-menu-vertical:not(.k-context-menu) {
        padding-block: k-spacing(2);
    }

    .k-menu-popup :is(.k-menu-group, .k-menu-vertical) {
        padding: 0;
    }

    .k-context-menu-popup {
        > .k-popup:not(.k-menu-popup) {
            padding: 0;
        }

        .k-context-menu {
            @include border-radius( k-border-radius(md) );
        }

        .k-header {
            font-size: inherit;
            font-weight: inherit;
        }
    }

}
