@use "../core/_index.scss" as *;

// Signature

$kendo-signature-width: 246px !default;
$kendo-signature-height: 108px !default;

$kendo-signature-lg-min-height: 110px !default;

$kendo-signature-maximized-width: 750px !default;
$kendo-signature-maximized-height: 252px !default;

$kendo-signature-padding-x: k-spacing(3) !default;
$kendo-signature-sm-padding-x: k-spacing(2) !default;
$kendo-signature-md-padding-x: $kendo-signature-padding-x !default;
$kendo-signature-lg-padding-x: k-spacing(4) !default;

$kendo-signature-padding-y: k-spacing(1) !default;
$kendo-signature-sm-padding-y: k-spacing(0.5) !default;
$kendo-signature-md-padding-y: $kendo-signature-padding-y !default;
$kendo-signature-lg-padding-y: k-spacing(2) !default;

$kendo-signature-line-width: 1px !default;
$kendo-signature-line-style: dashed !default;
$kendo-signature-line-color: if($kendo-enable-color-system, color-mix(in srgb, k-color( info ) 24%, transparent), rgba( $kendo-color-info, .24 )) !default;

$kendo-signature-line-size: calc( 100% - 2 * #{$kendo-signature-padding-x} ) !default;
$kendo-signature-sm-line-size: calc( 100% - 2 * #{$kendo-signature-sm-padding-x} ) !default;
$kendo-signature-md-line-size: calc( 100% - 2 * #{$kendo-signature-md-padding-x} ) !default;
$kendo-signature-lg-line-size: calc( 100% - 2 * #{$kendo-signature-lg-padding-x} ) !default;

$kendo-signature-line-bottom-offset: 33% !default;

$kendo-signature-sizes: (
    sm: (
        padding-x: $kendo-signature-sm-padding-x,
        padding-y: $kendo-signature-sm-padding-y,
        line-size: $kendo-signature-sm-line-size
    ),
    md: (
        padding-x: $kendo-signature-md-padding-x,
        padding-y: $kendo-signature-md-padding-y,
        line-size: $kendo-signature-md-line-size
    ),
    lg: (
        padding-x: $kendo-signature-lg-padding-x,
        padding-y: $kendo-signature-lg-padding-y,
        line-size: $kendo-signature-lg-line-size
    )
) !default;

$kendo-signature-actions-gap: k-spacing(1) !default;
$kendo-signature-maximized-line-width: 3px !default;


@forward "@progress/kendo-theme-default/scss/signature/_variables.scss" with (
    $kendo-signature-width: $kendo-signature-width,
    $kendo-signature-height: $kendo-signature-height,
    $kendo-signature-lg-min-height: $kendo-signature-lg-min-height,
    $kendo-signature-maximized-width: $kendo-signature-maximized-width,
    $kendo-signature-maximized-height: $kendo-signature-maximized-height,
    $kendo-signature-padding-x: $kendo-signature-padding-x,
    $kendo-signature-sm-padding-x: $kendo-signature-sm-padding-x,
    $kendo-signature-md-padding-x: $kendo-signature-md-padding-x,
    $kendo-signature-lg-padding-x: $kendo-signature-lg-padding-x,
    $kendo-signature-padding-y: $kendo-signature-padding-y,
    $kendo-signature-sm-padding-y: $kendo-signature-sm-padding-y,
    $kendo-signature-md-padding-y: $kendo-signature-md-padding-y,
    $kendo-signature-lg-padding-y: $kendo-signature-lg-padding-y,
    $kendo-signature-line-width: $kendo-signature-line-width,
    $kendo-signature-line-style: $kendo-signature-line-style,
    $kendo-signature-line-color: $kendo-signature-line-color,
    $kendo-signature-line-size: $kendo-signature-line-size,
    $kendo-signature-sm-line-size: $kendo-signature-sm-line-size,
    $kendo-signature-md-line-size: $kendo-signature-md-line-size,
    $kendo-signature-lg-line-size: $kendo-signature-lg-line-size,
    $kendo-signature-line-bottom-offset: $kendo-signature-line-bottom-offset,
    $kendo-signature-sizes: $kendo-signature-sizes,
    $kendo-signature-actions-gap: $kendo-signature-actions-gap,
    $kendo-signature-maximized-line-width: $kendo-signature-maximized-line-width
);
