@use "../core/_index.scss" as *;
@use "./_variables.scss" as *;
@use "../input/_variables.scss" as *;

@use "@progress/kendo-theme-default/scss/forms/_layout.scss" as *;

@mixin kendo-form--layout() {

    @include kendo-form--layout-base();

    // Forms
    .k-form,
    .k-form-inline {
        legend {
            font-size: calc( var( --kendo-font-size, .875rem ) * 1.5 );
            font-weight: normal;
            line-height: var( --kendo-line-height, normal );
            text-transform: none;
        }

        .k-field-info {
            opacity: .7;
        }
    }

    .k-form {
        legend {
            margin-bottom: k-spacing(2);
        }

        .k-field-info {
            margin-block: 0;
            margin-inline: calc( #{k-spacing(4)} / 2 );
        }
    }

    .k-form-inline {
        legend {
            margin-bottom: 0;
        }

        .k-form-field {
            > span:not(.k-widget) {
                padding: calc( #{k-spacing(4)} - #{$kendo-input-border-width} ) 0;
                padding-right: k-spacing(4);
            }
        }
    }

    .k-edit-label {
        padding: calc( #{k-spacing(2)} - #{$kendo-input-border-width} ) 0;
    }

}
