$kendo-enable-typography: false !default;

$kendo-use-input-button-width: false !default;
$kendo-use-input-spinner-width: false !default;
$kendo-use-input-spinner-icon-offset: false !default;

$kendo-zindex-popup: 1 !default;
$kendo-zindex-window: 2 !default;

$kendo-focus-shadow: null !default;

$kendo-transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out !default;
