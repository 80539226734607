@use "sass:map";
@use "../core/_index.scss" as *;
@use "../action-buttons/_variables.scss" as *;

// Window

/// The width of the border around the Window.
/// @group window
$kendo-window-border-width: 1px !default;
/// The border radius of the Window.
/// @group window
$kendo-window-border-radius: k-border-radius(md) !default;
/// The font family of the Window.
/// @group window
$kendo-window-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Window.
/// @group window
$kendo-window-font-size: var( --kendo-font-size, inherit )  !default;
/// The line height of the Window.
/// @group window
$kendo-window-line-height: var( --kendo-line-height, normal ) !default;

/// The horizontal padding of the Window titlebar.
/// @group window
$kendo-window-titlebar-padding-x: k-spacing(4) !default;
/// The vertical padding of the Window titlebar.
/// @group window
$kendo-window-titlebar-padding-y: k-spacing(4) !default;
/// The width of the border of the Window titlebar.
/// @group window
$kendo-window-titlebar-border-width: 0 0 1px !default;
/// The style of the border of the Window titlebar.
/// @group window
$kendo-window-titlebar-border-style: solid !default;

/// The font size of the title of the Window.
/// @group window
$kendo-window-title-font-size: var( --kendo-font-size-lg, inherit ) !default;
/// The line height of the title of the Window.
/// @group window
$kendo-window-title-line-height: var( --kendo-line-height, normal ) !default;

/// The spacing between the buttons in the Window titlebar.
/// @group window
$kendo-window-actions-gap: k-spacing(2) !default;
/// The opacity of the buttons in the Window titlebar.
/// @group window
$kendo-window-action-opacity: .5 !default;
/// The opacity of the hovered buttons in the Window titlebar.
/// @group window
$kendo-window-action-hover-opacity: .75 !default;

/// The horizontal padding of the content of the Window.
/// @group window
$kendo-window-inner-padding-x: k-spacing(4) !default;
/// The vertical padding of the content of the Window.
/// @group window
$kendo-window-inner-padding-y: k-spacing(4) !default;

/// The horizontal padding of the Window action buttons.
/// @group window
$kendo-window-buttongroup-padding-x: $kendo-actions-padding-x !default;
/// The vertical padding of the Window action buttons.
/// @group window
$kendo-window-buttongroup-padding-y: $kendo-actions-padding-y !default;
/// The width of the top border of the Window action buttons.
/// @group window
$kendo-window-buttongroup-border-width: 1px !default;

/// The background color of the Window.
/// @group window
$kendo-window-bg: $kendo-component-bg !default;
/// The text color of the Window.
/// @group window
$kendo-window-text: $kendo-component-text !default;
/// The border color of the Window.
/// @group window
$kendo-window-border: $kendo-component-border !default;
/// The box shadow of the Window.
/// @group window
$kendo-window-shadow: none !default;
/// The box shadow of the focused Window.
/// @group window
$kendo-window-focus-shadow: none !default;

/// The background color of the Window titlebar.
/// @group window
$kendo-window-titlebar-bg: unset !default;
/// The text color of the Window titlebar.
/// @group window
$kendo-window-titlebar-text: inherit !default;
/// The border color of the Window titlebar.
/// @group window
$kendo-window-titlebar-border: $kendo-component-border !default;
/// The background gradient of the Window titlebar.
/// @group window
$kendo-window-titlebar-gradient: null !default;

/// The map of the width for the different Window sizes.
/// @group window
$kendo-window-sizes: (
    sm: 300px,
    md: 800px,
    lg: 1200px
) !default;

/// The theme colors map for the Window.
/// @group window
$kendo-window-theme-colors: (
    "primary": map.get($kendo-theme-colors, "primary"),
    "light": map.get($kendo-theme-colors, "light"),
    "dark": map.get($kendo-theme-colors, "dark")
) !default;


@forward "@progress/kendo-theme-default/scss/window/_variables.scss" with (
    $kendo-window-border-width: $kendo-window-border-width,
    $kendo-window-border-radius: $kendo-window-border-radius,
    $kendo-window-font-family: $kendo-window-font-family,
    $kendo-window-font-size: $kendo-window-font-size,
    $kendo-window-line-height: $kendo-window-line-height,
    $kendo-window-titlebar-padding-x: $kendo-window-titlebar-padding-x,
    $kendo-window-titlebar-padding-y: $kendo-window-titlebar-padding-y,
    $kendo-window-titlebar-border-width: $kendo-window-titlebar-border-width,
    $kendo-window-titlebar-border-style: $kendo-window-titlebar-border-style,
    $kendo-window-title-font-size: $kendo-window-title-font-size,
    $kendo-window-title-line-height: $kendo-window-title-line-height,
    $kendo-window-actions-gap: $kendo-window-actions-gap,
    $kendo-window-action-opacity: $kendo-window-action-opacity,
    $kendo-window-action-hover-opacity: $kendo-window-action-hover-opacity,
    $kendo-window-inner-padding-x: $kendo-window-inner-padding-x,
    $kendo-window-inner-padding-y: $kendo-window-inner-padding-y,
    $kendo-window-buttongroup-padding-x: $kendo-window-buttongroup-padding-x,
    $kendo-window-buttongroup-padding-y: $kendo-window-buttongroup-padding-y,
    $kendo-window-buttongroup-border-width: $kendo-window-buttongroup-border-width,
    $kendo-window-bg: $kendo-window-bg,
    $kendo-window-text: $kendo-window-text,
    $kendo-window-border: $kendo-window-border,
    $kendo-window-shadow: $kendo-window-shadow,
    $kendo-window-focus-shadow: $kendo-window-focus-shadow,
    $kendo-window-titlebar-bg: $kendo-window-titlebar-bg,
    $kendo-window-titlebar-text: $kendo-window-titlebar-text,
    $kendo-window-titlebar-border: $kendo-window-titlebar-border,
    $kendo-window-titlebar-gradient: $kendo-window-titlebar-gradient,
    $kendo-window-sizes: $kendo-window-sizes,
    $kendo-window-theme-colors: $kendo-window-theme-colors
);
