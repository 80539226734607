@use "sass:map";

/// The base font size across all components.
/// @group typography
$kendo-font-size: 1rem !default;
/// The extra small font size across all components.
/// @group typography
$kendo-font-size-xs: ( $kendo-font-size * .75 ) !default;
/// The small font size across all components.
/// @group typography
$kendo-font-size-sm: ( $kendo-font-size * .875 ) !default;
/// The medium font size across all components.
/// @group typography
$kendo-font-size-md: $kendo-font-size !default;
/// The large font size across all components.
/// @group typography
$kendo-font-size-lg: ( $kendo-font-size * 1.25 ) !default;
/// The extra large font size across all components.
/// @group typography
$kendo-font-size-xl: ( $kendo-font-size-md * 1.5 ) !default;

/// The base line height across all components.
/// @group typography
$kendo-line-height: 1.5 !default;
/// The extra small line height across all components.
/// @group typography
$kendo-line-height-xs: 1 !default;
/// The small line height across all components.
/// @group typography
$kendo-line-height-sm: 1.25 !default;
/// The medium line height across all components.
/// @group typography
$kendo-line-height-md: $kendo-line-height !default;
/// The large line height across all components.
/// @group typography
$kendo-line-height-lg: 2 !default;
/// The base line height in ems across all components.
/// @group typography
$kendo-line-height-em: calc( #{$kendo-line-height} * 1em ) !default;

/// The light font weight across all components.
/// @group typography
$kendo-font-weight-light: 300 !default;
/// The base font weight across all components.
/// @group typography
$kendo-font-weight-normal: 400 !default;
/// The medium font weight across all components.
/// @group typography
$kendo-font-weight-medium: 500 !default;
/// The semibold font weight across all components.
/// @group typography
$kendo-font-weight-semibold: 600 !default;
/// The bold font weight across all components.
/// @group typography
$kendo-font-weight-bold: 700 !default;

/// The sans-serif font family across all components.
/// @group typography
$kendo-font-family-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
/// The monospace font family across all components.
/// @group typography
$kendo-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
/// The base font family across all components.
/// @group typography
$kendo-font-family: $kendo-font-family-sans-serif !default;

$_default-font-sizes: (
    xs: $kendo-font-size-xs,
    sm: $kendo-font-size-sm,
    md: $kendo-font-size-md,
    lg: $kendo-font-size-lg,
    xl: $kendo-font-size-xl
) !default;

$_default-line-heights: (
    xs: $kendo-line-height-xs,
    sm: $kendo-line-height-sm,
    md: $kendo-line-height-md,
    lg: $kendo-line-height-lg
) !default;

$_default-font-weights: (
    light: $kendo-font-weight-light,
    normal: $kendo-font-weight-normal,
    medium: $kendo-font-weight-medium,
    semibold: $kendo-font-weight-semibold,
    bold: $kendo-font-weight-bold
) !default;

$_default-font-families: (
    sans-serif: $kendo-font-family-sans-serif,
    monospace: $kendo-font-family-monospace
) !default;

/// The font sizes map
/// @group typography
$kendo-font-sizes: $_default-font-sizes !default;
$kendo-font-sizes: map.merge( $_default-font-sizes, $kendo-font-sizes );

/// The line heights map
/// @group typography
$kendo-line-heights: $_default-line-heights !default;
$kendo-line-heights: map.merge( $_default-line-heights, $kendo-line-heights );

/// The font weights map
/// @group typography
$kendo-font-weights: $_default-font-weights !default;
$kendo-font-weights: map.merge( $_default-font-weights, $kendo-font-weights );

/// The font families map
/// @group typography
$kendo-font-families: $_default-font-families !default;
$kendo-font-families: map.merge( $_default-font-families, $kendo-font-families );
