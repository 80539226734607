@use "sass:math";
@use "../core/_index.scss" as *;
// @use "../core/spacing/_index.scss" as *;

// Drag hint
$kendo-drag-hint-padding-x: k-spacing(3) !default;
$kendo-drag-hint-padding-y: k-spacing(1.5) !default;
$kendo-drag-hint-border-width: 1px !default;
$kendo-drag-hint-border-radius: k-border-radius(md) !default;
$kendo-drag-hint-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-drag-hint-font-family: var( --kendo-font-family, inherit )!default;
$kendo-drag-hint-line-height: var( --kendo-line-height, normal ) !default;

$kendo-drag-hint-bg: $kendo-selected-bg !default;
$kendo-drag-hint-text: $kendo-selected-text !default;
$kendo-drag-hint-border: $kendo-selected-border !default;
$kendo-drag-hint-gradient: null !default;
$kendo-drag-hint-shadow: null !default;

$kendo-drag-hint-opacity: null !default;


// Drop Hint
$kendo-drop-hint-arrow-size: 8px !default;
$kendo-drop-hint-arrow-spacing: math.div( $kendo-drop-hint-arrow-size, 2 ) !default;
$kendo-drop-hint-line-h-width: 20px !default;
$kendo-drop-hint-line-h-height: 1px !default;
$kendo-drop-hint-line-v-width: $kendo-drop-hint-line-h-height !default;
$kendo-drop-hint-line-v-height: $kendo-drop-hint-line-h-width !default;

$kendo-drop-hint-bg: $kendo-color-primary !default;


@forward "@progress/kendo-theme-default/scss/draggable/_variables.scss" with (
    $kendo-drag-hint-padding-x: $kendo-drag-hint-padding-x,
    $kendo-drag-hint-padding-y: $kendo-drag-hint-padding-y,
    $kendo-drag-hint-border-width: $kendo-drag-hint-border-width,
    $kendo-drag-hint-border-radius: $kendo-drag-hint-border-radius,
    $kendo-drag-hint-font-size: $kendo-drag-hint-font-size,
    $kendo-drag-hint-font-family: $kendo-drag-hint-font-family,
    $kendo-drag-hint-line-height: $kendo-drag-hint-line-height,
    $kendo-drag-hint-bg: $kendo-drag-hint-bg,
    $kendo-drag-hint-text: $kendo-drag-hint-text,
    $kendo-drag-hint-border: $kendo-drag-hint-border,
    $kendo-drag-hint-gradient: $kendo-drag-hint-gradient,
    $kendo-drag-hint-shadow: $kendo-drag-hint-shadow,
    $kendo-drag-hint-opacity: $kendo-drag-hint-opacity,
    $kendo-drop-hint-arrow-size: $kendo-drop-hint-arrow-size,
    $kendo-drop-hint-arrow-spacing: $kendo-drop-hint-arrow-spacing,
    $kendo-drop-hint-line-h-width: $kendo-drop-hint-line-h-width,
    $kendo-drop-hint-line-h-height: $kendo-drop-hint-line-h-height,
    $kendo-drop-hint-line-v-width: $kendo-drop-hint-line-v-width,
    $kendo-drop-hint-line-v-height: $kendo-drop-hint-line-v-height,
    $kendo-drop-hint-bg: $kendo-drop-hint-bg
);
