@use "../core/_index.scss" as *;
@use "../button/_variables.scss" as *;
@use "../list/_variables.scss" as *;

// Pager

/// The horizontal padding of the Pager.
/// @group pager
$kendo-pager-padding-x: k-spacing(2) !default;
/// The horizontal padding of the small Pager.
/// @group pager
$kendo-pager-sm-padding-x: k-spacing(1) !default;
/// The horizontal padding of the medium Pager.
/// @group pager
$kendo-pager-md-padding-x: k-spacing(2) !default;
/// The horizontal padding of the large Pager.
/// @group pager
$kendo-pager-lg-padding-x: k-spacing(2.5) !default;
/// The vertical padding of the Pager.
/// @group pager
$kendo-pager-padding-y: $kendo-pager-padding-x !default;
/// The vertical padding of the small Pager.
/// @group pager
$kendo-pager-sm-padding-y: $kendo-pager-sm-padding-x !default;
/// The vertical padding of the medium Pager.
/// @group pager
$kendo-pager-md-padding-y: $kendo-pager-md-padding-x !default;
/// The vertical padding of the large Pager.
/// @group pager
$kendo-pager-lg-padding-y: $kendo-pager-lg-padding-x !default;

/// The minimum width of the items in the small Pager.
/// @group pager
$kendo-pager-sm-item-min-width: $kendo-button-sm-calc-size !default;
/// The minimum width of the items in the medium Pagers.
/// @group pager
$kendo-pager-md-item-min-width: $kendo-button-md-calc-size !default;
/// The minimum width of the items in the large Pagers.
/// @group pager
$kendo-pager-lg-item-min-width: $kendo-button-lg-calc-size !default;

/// The margin between the item groups in the small Pager.
/// @group pager
$kendo-pager-sm-item-group-spacing: k-spacing(3.5) !default;
/// The margin between the item groups in the medium Pager.
/// @group pager
$kendo-pager-md-item-group-spacing: k-spacing(4) !default;
/// The margin between the item groups in the large Pager.
/// @group pager
$kendo-pager-lg-item-group-spacing: k-spacing(4.5) !default;

/// The border width of the Pager.
/// @group pager
$kendo-pager-border-width: 1px !default;
/// The font family of the Pager.
/// @group pager
$kendo-pager-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Pager.
/// @group pager
$kendo-pager-font-size: var( --kendo-font-size, inherit )  !default;
/// The line height of the Pager.
/// @group pager
$kendo-pager-line-height: var( --kendo-line-height, normal ) !default;
/// The background color of the Pager.
/// @group pager
$kendo-pager-bg: $kendo-component-header-bg !default;
/// The text color of the Pager.
/// @group pager
$kendo-pager-text: $kendo-component-header-text !default;
/// The border color of the Pager.
/// @group pager
$kendo-pager-border: $kendo-component-header-border !default;

/// The background color of the focused Pager.
/// @group pager
$kendo-pager-focus-bg: null !default;
/// The box shadow of the focused Pager.
/// @group pager
$kendo-pager-focus-shadow: inset 0 0 0 2px rgba( $kendo-color-black, .08) !default;

/// The spacing between the Pager sections.
/// @group pager
$kendo-pager-section-spacing: k-spacing(4) !default;

/// The border width of the Pager items.
/// @group pager
$kendo-pager-item-border-width: 1px !default;
/// The border radius of the Pager items.
/// @group pager
$kendo-pager-item-border-radius: k-spacing(1) !default;
/// The spacing around the Pager items.
/// @group pager
$kendo-pager-item-spacing: (-1 * $kendo-pager-item-border-width) !default;
/// The background color of the Pager items.
/// @group pager
$kendo-pager-item-bg: $kendo-component-bg !default;
/// The text color of the Pager items.
/// @group pager
$kendo-pager-item-text: $kendo-link-text !default;
/// The border color of the Pager items.
/// @group pager
$kendo-pager-item-border: $kendo-component-border !default;

/// The background color of the hovered Pager items.
/// @group pager
$kendo-pager-item-hover-bg: $kendo-hover-bg !default;
/// The text color of the hovered Pager items.
/// @group pager
$kendo-pager-item-hover-text: $kendo-link-hover-text !default;
/// The border color of the hovered Pager items.
/// @group pager
$kendo-pager-item-hover-border: $kendo-hover-border !default;
/// The background color of the selected Pager items.
/// @group pager
$kendo-pager-item-selected-bg: $kendo-selected-bg !default;
/// The text color of the selected Pager items.
/// @group pager
$kendo-pager-item-selected-text: $kendo-selected-text !default;
/// The border color of the selected Pager items.
/// @group pager
$kendo-pager-item-selected-border: $kendo-pager-item-selected-bg !default;
/// The opacity of the focused Pager items.
/// @group pager
$kendo-pager-item-focus-opacity: null !default;
/// The background color of the focused Pager items.
/// @group pager
$kendo-pager-item-focus-bg: $kendo-pager-item-bg !default;
/// The box shadow of the focused Pager items.
/// @group pager
$kendo-pager-item-focus-shadow: 0 0 0 3px if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 25%, transparent), rgba($kendo-color-primary, .25)) !default;

/// The border radius of the Pager numbers.
/// @group pager
$kendo-pager-number-border-radius: 0px !default;

/// The width of the Inputs in the Pager.
/// @group pager
$kendo-pager-input-width: 5em !default;

/// The width of the DropDowns in the small Pager.
/// @group pager
$kendo-pager-sm-dropdown-width: 5em !default;
/// The width of the DropDowns in the medium Pager.
/// @group pager
$kendo-pager-md-dropdown-width: 5em !default;
/// The width of the DropDowns in the large Pager.
/// @group pager
$kendo-pager-lg-dropdown-width: 6em !default;

/// The sizes map of the Pager.
/// @group pager
$kendo-pager-sizes: (
    sm: (
        padding-x: $kendo-pager-sm-padding-x,
        padding-y: $kendo-pager-sm-padding-y,
        item-group-spacing: $kendo-pager-sm-item-group-spacing,
        item-min-width: $kendo-pager-sm-item-min-width,
        pager-dropdown-width: $kendo-pager-sm-dropdown-width
    ),
    md: (
        padding-x: $kendo-pager-md-padding-x,
        padding-y: $kendo-pager-md-padding-y,
        item-group-spacing: $kendo-pager-md-item-group-spacing,
        item-min-width: $kendo-pager-md-item-min-width,
        pager-dropdown-width: $kendo-pager-md-dropdown-width
    ),
    lg: (
        padding-x: $kendo-pager-lg-padding-x,
        padding-y: $kendo-pager-lg-padding-y,
        item-group-spacing: $kendo-pager-lg-item-group-spacing,
        item-min-width: $kendo-pager-lg-item-min-width,
        pager-dropdown-width: $kendo-pager-lg-dropdown-width
    )
) !default;


@forward "@progress/kendo-theme-default/scss/pager/_variables.scss" with (
    $kendo-pager-padding-x: $kendo-pager-padding-x,
    $kendo-pager-sm-padding-x: $kendo-pager-sm-padding-x,
    $kendo-pager-md-padding-x: $kendo-pager-md-padding-x,
    $kendo-pager-lg-padding-x: $kendo-pager-lg-padding-x,
    $kendo-pager-padding-y: $kendo-pager-padding-y,
    $kendo-pager-sm-padding-y: $kendo-pager-sm-padding-y,
    $kendo-pager-md-padding-y: $kendo-pager-md-padding-y,
    $kendo-pager-lg-padding-y: $kendo-pager-lg-padding-y,
    $kendo-pager-sm-item-min-width: $kendo-pager-sm-item-min-width,
    $kendo-pager-md-item-min-width: $kendo-pager-md-item-min-width,
    $kendo-pager-lg-item-min-width: $kendo-pager-lg-item-min-width,
    $kendo-pager-sm-item-group-spacing: $kendo-pager-sm-item-group-spacing,
    $kendo-pager-md-item-group-spacing: $kendo-pager-md-item-group-spacing,
    $kendo-pager-lg-item-group-spacing: $kendo-pager-lg-item-group-spacing,
    $kendo-pager-border-width: $kendo-pager-border-width,
    $kendo-pager-font-family: $kendo-pager-font-family,
    $kendo-pager-font-size: $kendo-pager-font-size,
    $kendo-pager-line-height: $kendo-pager-line-height,
    $kendo-pager-bg: $kendo-pager-bg,
    $kendo-pager-text: $kendo-pager-text,
    $kendo-pager-border: $kendo-pager-border,
    $kendo-pager-focus-bg: $kendo-pager-focus-bg,
    $kendo-pager-focus-shadow: $kendo-pager-focus-shadow,
    // $kendo-pager-section-spacing: $kendo-pager-section-spacing,
    $kendo-pager-item-border-width: $kendo-pager-item-border-width,
    $kendo-pager-item-border-radius: $kendo-pager-item-border-radius,
    $kendo-pager-item-spacing: $kendo-pager-item-spacing,
    $kendo-pager-item-bg: $kendo-pager-item-bg,
    $kendo-pager-item-text: $kendo-pager-item-text,
    $kendo-pager-item-border: $kendo-pager-item-border,
    $kendo-pager-item-hover-bg: $kendo-pager-item-hover-bg,
    $kendo-pager-item-hover-text: $kendo-pager-item-hover-text,
    $kendo-pager-item-hover-border: $kendo-pager-item-hover-border,
    $kendo-pager-item-selected-bg: $kendo-pager-item-selected-bg,
    $kendo-pager-item-selected-text: $kendo-pager-item-selected-text,
    $kendo-pager-item-selected-border: $kendo-pager-item-selected-border,
    $kendo-pager-item-focus-opacity: $kendo-pager-item-focus-opacity,
    $kendo-pager-item-focus-bg: $kendo-pager-item-focus-bg,
    $kendo-pager-item-focus-shadow: $kendo-pager-item-focus-shadow,
    $kendo-pager-number-border-radius: $kendo-pager-number-border-radius,
    $kendo-pager-input-width: $kendo-pager-input-width,
    $kendo-pager-sm-dropdown-width: $kendo-pager-sm-dropdown-width,
    $kendo-pager-md-dropdown-width: $kendo-pager-md-dropdown-width,
    $kendo-pager-lg-dropdown-width: $kendo-pager-lg-dropdown-width,
    $kendo-pager-sizes: $kendo-pager-sizes
);
