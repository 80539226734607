@use "../core/_index.scss" as *;
@use "../list/_variables.scss" as *;

// TabStrip


/// The horizontal padding of the TabStrip wrapper.
/// @group tabstrip
$kendo-tabstrip-wrapper-padding-x: k-spacing(0) !default;
/// The vertical padding of the TabStrip wrapper.
/// @group tabstrip
$kendo-tabstrip-wrapper-padding-y: k-spacing(0) !default;
/// The border width around the TabStrip wrapper.
/// @group tabstrip
$kendo-tabstrip-wrapper-border-width: 0px !default;

/// The font family of the TabStrip.
/// @group tabstrip
$kendo-tabstrip-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the TabStrip.
/// @group tabstrip
$kendo-tabstrip-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the TabStrip.
/// @group tabstrip
$kendo-tabstrip-line-height: var( --kendo-line-height, normal ) !default;
/// The border width around the TabStrip.
/// @group tabstrip
$kendo-tabstrip-border-width: 1px !default;

/// The font size of the small TabStrip.
/// @group tabstrip
$kendo-tabstrip-sm-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The line height of the small TabStrip.
/// @group tabstrip
$kendo-tabstrip-sm-line-height: $kendo-tabstrip-line-height !default;
/// The font size of the medium TabStrip.
/// @group tabstrip
$kendo-tabstrip-md-font-size: $kendo-tabstrip-font-size !default;
/// The line height of the medium TabStrip.
/// @group tabstrip
$kendo-tabstrip-md-line-height: $kendo-tabstrip-line-height !default;
/// The font size of the large TabStrip.
/// @group tabstrip
$kendo-tabstrip-lg-font-size: $kendo-tabstrip-md-font-size !default;
/// The line height of the large TabStrip.
/// @group tabstrip
$kendo-tabstrip-lg-line-height: $kendo-tabstrip-md-line-height !default;

/// The background color of the TabStrip wrapper.
/// @group tabstrip
$kendo-tabstrip-wrapper-bg: null !default;
/// The text color of the TabStrip wrapper.
/// @group tabstrip
$kendo-tabstrip-wrapper-text: null !default;
/// The border color of the TabStrip wrapper.
/// @group tabstrip
$kendo-tabstrip-wrapper-border: null !default;

/// The background color of the TabStrip.
/// @group tabstrip
$kendo-tabstrip-bg: null !default;
/// The text color of the TabStrip.
/// @group tabstrip
$kendo-tabstrip-text: $kendo-component-text !default;
/// The border color of the TabStrip.
/// @group tabstrip
$kendo-tabstrip-border: $kendo-component-border !default;

/// The horizontal padding of the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-padding-x: k-spacing(4) !default;
/// The vertical padding of the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-padding-y: k-spacing(2) !default;
/// The border width around the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-border-width: 1px !default;
/// The border radius of the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-border-radius: k-spacing(1) !default;
/// The gap between the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-gap: k-spacing(0) !default;
/// The horizontal padding of the small TabStrip items.
/// @group tabstrip
$kendo-tabstrip-sm-item-padding-x: k-spacing(3) !default;
/// The vertical padding of the small TabStrip items.
/// @group tabstrip
$kendo-tabstrip-sm-item-padding-y: k-spacing(1.5) !default;
/// The horizontal padding of the medium TabStrip items.
/// @group tabstrip
$kendo-tabstrip-md-item-padding-x: $kendo-tabstrip-item-padding-x !default;
/// The vertical padding of the medium TabStrip items.
/// @group tabstrip
$kendo-tabstrip-md-item-padding-y: $kendo-tabstrip-item-padding-y !default;
/// The horizontal padding of the large TabStrip items.
/// @group tabstrip
$kendo-tabstrip-lg-item-padding-x: k-spacing(5) !default;
/// The vertical padding of the large TabStrip items.
/// @group tabstrip
$kendo-tabstrip-lg-item-padding-y: k-spacing(2.5) !default;

///  The background color of the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-bg: null !default;
/// The text color of the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-text: $kendo-link-text !default;
/// The border color of the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-border: null !default;
/// The gradient of the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-gradient: null !default;

/// The background color of the hovered TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-hover-bg: null !default;
/// The text color of the hovered TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-hover-text: $kendo-link-hover-text !default;
/// The border color of the hovered TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-hover-border: if($kendo-enable-color-system, k-color( base-subtle ), #e9ecef) !default;
/// The gradient of the hovered TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-hover-gradient: null !default;

/// The background color of the selected TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-selected-bg: $kendo-component-bg !default;
/// The text color of the selected TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-selected-text: $kendo-component-text !default;
/// The border color of the selected TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-selected-border: $kendo-component-border !default;
/// The gradient of the selected TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-selected-gradient: null !default;
/// The font weight of the selected TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-selected-font-weight: var( --kendo-font-weight-bold, normal ) !default;

/// The shadow of the focused TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-focus-shadow: $kendo-list-item-focus-shadow !default;

/// The shadow of the dragged TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-dragging-shadow: k-elevation(3) !default;

/// The background color of the disabled TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-disabled-bg: null !default;
/// The text color of the disabled TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-disabled-text: $kendo-component-text !default;
/// The border color of the disabled TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-disabled-border: null !default;
/// The gradient of the disabled TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-disabled-gradient: null !default;
/// The opacity of the disabled TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-disabled-opacity: null !default;
/// The filter of the disabled TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-disabled-filter: null !default;

/// The border width of the TabStrip indicator.
/// @group tabstrip
$kendo-tabstrip-indicator-size: null !default;
/// The border color of the TabStrip ripple.
/// @group tabstrip
$kendo-tabstrip-indicator-color: null !default;

/// The horizontal padding of the TabStrip content.
/// @group tabstrip
$kendo-tabstrip-content-padding-x: k-spacing(4) !default;
/// The vertical padding of the TabStrip content.
/// @group tabstrip
$kendo-tabstrip-content-padding-y: k-spacing(4) !default;
/// The border width around the TabStrip content.
/// @group tabstrip
$kendo-tabstrip-content-border-width: $kendo-tabstrip-border-width !default;

/// The background color of the TabStrip content.
/// @group tabstrip
$kendo-tabstrip-content-bg: $kendo-component-bg !default;
/// The text color of the TabStrip content.
/// @group tabstrip
$kendo-tabstrip-content-text: $kendo-component-text !default;
/// The border color of the TabStrip content.
/// @group tabstrip
$kendo-tabstrip-content-border: $kendo-component-border !default;
/// The border color of the focused TabStrip content.
/// @group tabstrip
$kendo-tabstrip-content-focus-border: $kendo-component-text !default;

/// The left and right scroll overlay of the TabStrip.
/// @group tabstrip
$kendo-tabstrip-scroll-overlay: if($kendo-enable-color-system, k-color( app-surface ), rgba( $kendo-color-white, 0)), if($kendo-enable-color-system, color-mix(in srgb, k-color( app-surface ) 0%, transparent), rgba( $kendo-color-white, 0)) !default;

/// The size map of the TabStrip.
/// @group tabstrip
$kendo-tabstrip-sizes: (
    sm: (
        font-size: $kendo-tabstrip-sm-font-size,
        line-height: $kendo-tabstrip-sm-line-height,
        item-padding-x: $kendo-tabstrip-sm-item-padding-x,
        item-padding-y: $kendo-tabstrip-sm-item-padding-y
    ),
    md: (
        font-size: $kendo-tabstrip-md-font-size,
        line-height: $kendo-tabstrip-md-line-height,
        item-padding-x: $kendo-tabstrip-md-item-padding-x,
        item-padding-y: $kendo-tabstrip-md-item-padding-y
    ),
    lg: (
        font-size: $kendo-tabstrip-lg-font-size,
        line-height: $kendo-tabstrip-lg-line-height,
        item-padding-x: $kendo-tabstrip-lg-item-padding-x,
        item-padding-y: $kendo-tabstrip-lg-item-padding-y
    )
) !default;

@forward "@progress/kendo-theme-default/scss/tabstrip/_variables.scss" with (
    $kendo-tabstrip-wrapper-padding-x: $kendo-tabstrip-wrapper-padding-x,
    $kendo-tabstrip-wrapper-padding-y: $kendo-tabstrip-wrapper-padding-y,
    $kendo-tabstrip-wrapper-border-width: $kendo-tabstrip-wrapper-border-width,
    $kendo-tabstrip-font-family: $kendo-tabstrip-font-family,
    $kendo-tabstrip-font-size: $kendo-tabstrip-font-size,
    $kendo-tabstrip-line-height: $kendo-tabstrip-line-height,
    $kendo-tabstrip-border-width: $kendo-tabstrip-border-width,
    $kendo-tabstrip-sm-font-size: $kendo-tabstrip-sm-font-size,
    $kendo-tabstrip-sm-line-height: $kendo-tabstrip-sm-line-height,
    $kendo-tabstrip-md-font-size: $kendo-tabstrip-md-font-size,
    $kendo-tabstrip-md-line-height: $kendo-tabstrip-md-line-height,
    $kendo-tabstrip-lg-font-size: $kendo-tabstrip-lg-font-size,
    $kendo-tabstrip-lg-line-height: $kendo-tabstrip-lg-line-height,
    $kendo-tabstrip-wrapper-bg: $kendo-tabstrip-wrapper-bg,
    $kendo-tabstrip-wrapper-text: $kendo-tabstrip-wrapper-text,
    $kendo-tabstrip-wrapper-border: $kendo-tabstrip-wrapper-border,
    $kendo-tabstrip-bg: $kendo-tabstrip-bg,
    $kendo-tabstrip-text: $kendo-tabstrip-text,
    $kendo-tabstrip-border: $kendo-tabstrip-border,
    $kendo-tabstrip-item-padding-x: $kendo-tabstrip-item-padding-x,
    $kendo-tabstrip-item-padding-y: $kendo-tabstrip-item-padding-y,
    $kendo-tabstrip-item-border-width: $kendo-tabstrip-item-border-width,
    $kendo-tabstrip-item-border-radius: $kendo-tabstrip-item-border-radius,
    $kendo-tabstrip-item-gap: $kendo-tabstrip-item-gap,
    $kendo-tabstrip-sm-item-padding-x: $kendo-tabstrip-sm-item-padding-x,
    $kendo-tabstrip-sm-item-padding-y: $kendo-tabstrip-sm-item-padding-y,
    $kendo-tabstrip-md-item-padding-x: $kendo-tabstrip-md-item-padding-x,
    $kendo-tabstrip-md-item-padding-y: $kendo-tabstrip-md-item-padding-y,
    $kendo-tabstrip-lg-item-padding-x: $kendo-tabstrip-lg-item-padding-x,
    $kendo-tabstrip-lg-item-padding-y: $kendo-tabstrip-lg-item-padding-y,
    $kendo-tabstrip-item-bg: $kendo-tabstrip-item-bg,
    $kendo-tabstrip-item-text: $kendo-tabstrip-item-text,
    $kendo-tabstrip-item-border: $kendo-tabstrip-item-border,
    $kendo-tabstrip-item-gradient: $kendo-tabstrip-item-gradient,
    $kendo-tabstrip-item-hover-bg: $kendo-tabstrip-item-hover-bg,
    $kendo-tabstrip-item-hover-text: $kendo-tabstrip-item-hover-text,
    $kendo-tabstrip-item-hover-border: $kendo-tabstrip-item-hover-border,
    $kendo-tabstrip-item-hover-gradient: $kendo-tabstrip-item-hover-gradient,
    $kendo-tabstrip-item-selected-bg: $kendo-tabstrip-item-selected-bg,
    $kendo-tabstrip-item-selected-text: $kendo-tabstrip-item-selected-text,
    $kendo-tabstrip-item-selected-border: $kendo-tabstrip-item-selected-border,
    $kendo-tabstrip-item-selected-gradient: $kendo-tabstrip-item-selected-gradient,
    $kendo-tabstrip-item-selected-font-weight: $kendo-tabstrip-item-selected-font-weight,
    $kendo-tabstrip-item-focus-shadow: $kendo-tabstrip-item-focus-shadow,
    $kendo-tabstrip-item-dragging-shadow: $kendo-tabstrip-item-dragging-shadow,
    $kendo-tabstrip-item-disabled-bg: $kendo-tabstrip-item-disabled-bg,
    $kendo-tabstrip-item-disabled-text: $kendo-tabstrip-item-disabled-text,
    $kendo-tabstrip-item-disabled-border: $kendo-tabstrip-item-disabled-border,
    $kendo-tabstrip-item-disabled-gradient: $kendo-tabstrip-item-disabled-gradient,
    $kendo-tabstrip-item-disabled-opacity: $kendo-tabstrip-item-disabled-opacity,
    $kendo-tabstrip-item-disabled-filter: $kendo-tabstrip-item-disabled-filter,
    $kendo-tabstrip-indicator-size: $kendo-tabstrip-indicator-size,
    $kendo-tabstrip-indicator-color: $kendo-tabstrip-indicator-color,
    $kendo-tabstrip-content-padding-x: $kendo-tabstrip-content-padding-x,
    $kendo-tabstrip-content-padding-y: $kendo-tabstrip-content-padding-y,
    $kendo-tabstrip-content-border-width: $kendo-tabstrip-content-border-width,
    $kendo-tabstrip-content-bg: $kendo-tabstrip-content-bg,
    $kendo-tabstrip-content-text: $kendo-tabstrip-content-text,
    $kendo-tabstrip-content-border: $kendo-tabstrip-content-border,
    $kendo-tabstrip-content-focus-border: $kendo-tabstrip-content-focus-border,
    $kendo-tabstrip-scroll-overlay: $kendo-tabstrip-scroll-overlay
);
