@use "../core/_index.scss" as *;
@use "@progress/kendo-theme-default/scss/loader/_layout.scss" as *;

@mixin kendo-loader--layout() {

    @include kendo-loader--layout-base();
    @include kendo-loading--layout-base();

    .k-i-loading,
    .k-loading-image {

        &::before {
            border-color: currentColor;
            border-right-color: transparent !important; // stylelint-disable-line declaration-no-important
        }

        &::after {
            display: none !important; // stylelint-disable-line declaration-no-important
        }
    }

    .k-loading-image::before {
        border-width: k-spacing(1);
    }

}
