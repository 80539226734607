// @use "sass:map";
@use "../core/_index.scss" as *;
@use "../button/_variables.scss" as *;
@use "../core/functions/index.import.scss" as *;

// Floating Action Button

/// The width of the border around the FAB.
/// @group floating-action-button
$kendo-fab-border-width: 1px !default;
/// The border radius of the FAB.
/// @group floating-action-button
$kendo-fab-border-radius: calc( #{k-border-radius(md)} / 2 ) !default;
/// The font family of the FAB.
/// @group floating-action-button
$kendo-fab-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the FAB.
/// @group floating-action-button
$kendo-fab-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the FAB.
/// @group floating-action-button
$kendo-fab-line-height: var( --kendo-line-height, normal ) !default;

/// The horizontal padding of the FAB.
/// @group floating-action-button
$kendo-fab-padding-x: k-spacing(4) !default;
/// The horizontal padding of the small FAB.
/// @group floating-action-button
$kendo-fab-sm-padding-x: calc( #{$kendo-fab-padding-x} / 2 ) !default;
/// The horizontal padding of the medium FAB.
/// @group floating-action-button
$kendo-fab-md-padding-x: $kendo-fab-padding-x !default;
/// The horizontal padding of the large FAB.
/// @group floating-action-button
$kendo-fab-lg-padding-x: calc( #{$kendo-fab-padding-x} * 1.5 ) !default;

/// The vertical padding of the FAB.
/// @group floating-action-button
$kendo-fab-padding-y: $kendo-fab-padding-x !default;
/// The vertical padding of the small FAB.
/// @group floating-action-button
$kendo-fab-sm-padding-y: calc( #{$kendo-fab-padding-y} / 2 ) !default;
/// The vertical padding of the medium FAB.
/// @group floating-action-button
$kendo-fab-md-padding-y: $kendo-fab-padding-y !default;
/// The vertical padding of the large FAB.
/// @group floating-action-button
$kendo-fab-lg-padding-y: calc( #{$kendo-fab-padding-y} * 1.5 ) !default;

/// The horizontal padding of the FAB icon.
/// @group floating-action-button
$kendo-fab-icon-padding-x: k-spacing(1) !default;
/// The vertical padding of the FAB icon.
/// @group floating-action-button
$kendo-fab-icon-padding-y: $kendo-fab-icon-padding-x !default;
/// The spacing of the FAB icon.
/// @group floating-action-button
$kendo-fab-icon-spacing: k-spacing(0.5) !default;

/// The horizontal padding of the FAB items.
/// @group floating-action-button
$kendo-fab-items-padding-x: k-spacing(0) !default;
/// The vertical padding of the FAB items.
/// @group floating-action-button
$kendo-fab-items-padding-y: k-spacing(4) !default;

/// The horizontal padding of the FAB item text.
/// @group floating-action-button
$kendo-fab-item-text-padding-x: k-spacing(1) !default;
/// The vertical padding of the FAB item text.
/// @group floating-action-button
$kendo-fab-item-text-padding-y: $kendo-fab-item-text-padding-x !default;
/// The width of the FAB item text border.
/// @group floating-action-button
$kendo-fab-item-text-border-width: 1px !default;
/// The border radius of the FAB item text.
/// @group floating-action-button
$kendo-fab-item-text-border-radius: 4px !default;
/// The font size of the FAB item text.
/// @group floating-action-button
$kendo-fab-item-text-font-size: var( --kendo-font-size-xs, inherit ) !default;
/// The line height of the FAB item text.
/// @group floating-action-button
$kendo-fab-item-text-line-height: 14px !default;

/// The horizontal padding of the FAB item icon.
/// @group floating-action-button
$kendo-fab-item-icon-padding-x: calc( #{k-spacing(2)} + #{$kendo-fab-icon-padding-x} ) !default;
/// The vertical padding of the FAB item icon.
/// @group floating-action-button
$kendo-fab-item-icon-padding-y: $kendo-fab-item-icon-padding-x !default;
/// The width of the FAB item icon border.
/// @group floating-action-button
$kendo-fab-item-icon-border-width: 0 !default;
/// The border radius of the FAB item icon.
/// @group floating-action-button
$kendo-fab-item-icon-border-radius: 50% !default;

/// The theme colors map for the FAB.
/// @group floating-action-button
$kendo-fab-theme-colors: $kendo-theme-colors !default;

/// The size map for the FAB.
/// @group floating-action-button
$kendo-fab-sizes: (
    sm: (
        padding-x: $kendo-fab-sm-padding-x,
        padding-y: $kendo-fab-sm-padding-y
    ),
    md: (
        padding-x: $kendo-fab-md-padding-x,
        padding-y: $kendo-fab-md-padding-y
    ),
    lg: (
        padding-x: $kendo-fab-lg-padding-x,
        padding-y: $kendo-fab-lg-padding-y
    )
) !default;

/// The base shadow of the FAB.
/// @group floating-action-button
$kendo-fab-shadow: k-elevation(5) !default;
/// The shadow of the disabled FAB.
/// @group floating-action-button
$kendo-fab-disabled-shadow: k-elevation(5) !default;
/// The shadow of the active FAB.
/// @group floating-action-button
$kendo-fab-active-shadow: null !default;
/// The outline style of the FAB.
/// @group floating-action-button
$kendo-fab-outline-style: solid !default;
/// The outline width of the FAB.
/// @group floating-action-button
$kendo-fab-outline-width: 3px !default;

/// The base text color of the FAB item.
/// @group floating-action-button
$kendo-fab-item-text: $kendo-component-text !default;
/// The base background color of the FAB item.
/// @group floating-action-button
$kendo-fab-item-bg: $kendo-component-bg !default;
/// The base border color of the FAB item.
/// @group floating-action-button
$kendo-fab-item-border: $kendo-component-border !default;

/// The base text color of the FAB item icon.
/// @group floating-action-button
$kendo-fab-item-icon-text: $kendo-button-text !default;
/// The base background color of the FAB item icon.
/// @group floating-action-button
$kendo-fab-item-icon-bg: $kendo-button-bg !default;
/// The base border color of the FAB item icon.
/// @group floating-action-button
$kendo-fab-item-icon-border: $kendo-button-border !default;

/// The base shadow of the FAB item.
/// @group floating-action-button
$kendo-fab-item-shadow: $kendo-fab-shadow !default;
/// The shadow of the disabled FAB item.
/// @group floating-action-button
$kendo-fab-item-disabled-shadow: $kendo-fab-disabled-shadow !default;
/// The shadow of the active FAB item.
/// @group floating-action-button
$kendo-fab-item-active-shadow: $kendo-fab-active-shadow !default;
/// The outline style of the FAB item.
/// @group floating-action-button
$kendo-fab-item-outline-style: solid !default;
/// The outline width of the FAB item.
/// @group floating-action-button
$kendo-fab-item-outline-width: 3px !default;
/// The outline color of the FAB item.
/// @group floating-action-button
$kendo-fab-item-outline-color: $kendo-button-bg !default;


@forward "@progress/kendo-theme-default/scss/fab/_variables.scss" with (
    $kendo-fab-border-width: $kendo-fab-border-width,
    $kendo-fab-border-radius: $kendo-fab-border-radius,
    $kendo-fab-font-family: $kendo-fab-font-family,
    $kendo-fab-font-size: $kendo-fab-font-size,
    $kendo-fab-line-height: $kendo-fab-line-height,
    $kendo-fab-padding-x: $kendo-fab-padding-x,
    $kendo-fab-sm-padding-x: $kendo-fab-sm-padding-x,
    $kendo-fab-md-padding-x: $kendo-fab-md-padding-x,
    $kendo-fab-lg-padding-x: $kendo-fab-lg-padding-x,
    $kendo-fab-padding-y: $kendo-fab-padding-y,
    $kendo-fab-sm-padding-y: $kendo-fab-sm-padding-y,
    $kendo-fab-md-padding-y: $kendo-fab-md-padding-y,
    $kendo-fab-lg-padding-y: $kendo-fab-lg-padding-y,
    $kendo-fab-icon-padding-x: $kendo-fab-icon-padding-x,
    $kendo-fab-icon-padding-y: $kendo-fab-icon-padding-y,
    $kendo-fab-icon-spacing: $kendo-fab-icon-spacing,
    $kendo-fab-items-padding-x: $kendo-fab-items-padding-x,
    $kendo-fab-items-padding-y: $kendo-fab-items-padding-y,
    $kendo-fab-item-text-padding-x: $kendo-fab-item-text-padding-x,
    $kendo-fab-item-text-padding-y: $kendo-fab-item-text-padding-y,
    $kendo-fab-item-text-border-width: $kendo-fab-item-text-border-width,
    $kendo-fab-item-text-border-radius: $kendo-fab-item-text-border-radius,
    $kendo-fab-item-text-font-size: $kendo-fab-item-text-font-size,
    $kendo-fab-item-text-line-height: $kendo-fab-item-text-line-height,
    $kendo-fab-item-icon-padding-x: $kendo-fab-item-icon-padding-x,
    $kendo-fab-item-icon-padding-y: $kendo-fab-item-icon-padding-y,
    $kendo-fab-item-icon-border-width: $kendo-fab-item-icon-border-width,
    $kendo-fab-item-icon-border-radius: $kendo-fab-item-icon-border-radius,
    $kendo-fab-theme-colors: $kendo-fab-theme-colors,
    $kendo-fab-sizes: $kendo-fab-sizes,
    $kendo-fab-shadow: $kendo-fab-shadow,
    $kendo-fab-disabled-shadow: $kendo-fab-disabled-shadow,
    $kendo-fab-active-shadow: $kendo-fab-active-shadow,
    $kendo-fab-outline-style: $kendo-fab-outline-style,
    $kendo-fab-outline-width: $kendo-fab-outline-width,
    $kendo-fab-item-text: $kendo-fab-item-text,
    $kendo-fab-item-bg: $kendo-fab-item-bg,
    $kendo-fab-item-border: $kendo-fab-item-border,
    $kendo-fab-item-icon-text: $kendo-fab-item-icon-text,
    $kendo-fab-item-icon-bg: $kendo-fab-item-icon-bg,
    $kendo-fab-item-icon-border: $kendo-fab-item-icon-border,
    $kendo-fab-item-shadow: $kendo-fab-item-shadow,
    $kendo-fab-item-disabled-shadow: $kendo-fab-item-disabled-shadow,
    $kendo-fab-item-active-shadow: $kendo-fab-item-active-shadow,
    $kendo-fab-item-outline-style: $kendo-fab-item-outline-style,
    $kendo-fab-item-outline-width: $kendo-fab-item-outline-width,
    $kendo-fab-item-outline-color: $kendo-fab-item-outline-color
);
