﻿/* Override Kendo's base colors */
@use "@progress/kendo-theme-bootstrap/scss/all.scss" as * with (
    $kendo-colors: (
    primary: $gold,
    primary-hover: $gold-hover,
    base: #f5f5f5,
    base-hover: #ebebeb,
    primary-active: $gold,
    primary-on-surface: $gold,
  ),
  // BUTTON
  $kendo-button-border: #00000014,
  // CHECKBOX
  $kendo-checkbox-border: $gold,
  $kendo-checkbox-disabled-border: $light-gold,
  // GRID
  $kendo-table-header-bg: $light-gold,
  $kendo-table-header-border: rgba(0, 0, 0, 0.08),
  $kendo-grid-hover-bg: #dde,
  // INPUT
  $kendo-input-default-width: 218px,
);

$base-input-width: 200;
$base-input-xl-width: 400;
$base-input-xxl-width: 500;

$normal-border: 1px solid rgba(0, 0, 0, 0.08);
$normal-color: #333333;

/* Additional rules for grid */
kendo-grid thead {
    background-color: $light-gold;
    border-color: rgba(0, 0, 0, 0.08);
}

.k-grid thead, .k-grid-header {
    border-color: rgba(0, 0, 0, 0.08);
}

kendo-grid.navigable tr:hover td {
    background-color: #dde;
    cursor: pointer;
}

td[kendogridcell] .icon-centered {
    width: 100%;
    display: block;
    text-align: center;
}

td[kendogridcell] .click-to-expand:hover {
    cursor: zoom-in;
}

.no-scrollbar .k-grid-header {
    padding: 0 !important;
}

.no-scrollbar .k-grid-content {
    overflow-y: visible;
}

kendo-grid.inner thead {
    background-color: white;
    border-color: #dddddd;
}

kendo-grid.inner .k-grid-header th.k-header {
    font-weight: bold;
}

kendo-grid.inner th.k-header {
    line-height: 1em;
}

kendo-grid.boldheader .k-grid-header th.k-header {
    font-weight: bold;
}

#license-search-results-grid .k-grid-header, #license-search-results-grid .k-header {
    overflow: visible;
    white-space: normal;
}

kendo-pager.k-pager-wrap a.k-link.k-pager-first, kendo-pager.k-pager-wrap a.k-link.k-pager-last {
    display: none;
}

/* Buttons with icons */
.k-button i {
    margin-right: 10px;
    line-height: 20px;
}

button.icon-only i {
    margin-right: 0;
}

button:focus, li:focus {
    outline: 0;
}

/* Checkboxes */
input[type=checkbox] {
    transform: scale(1.2);
    margin-right: 5px;
}

/* Basic text inputs */
kendo-grid kendo-dialog input[type=text], input[type=text], input[type=password], input[type=email], input[type=url] {
    width: $base-input-width + unquote("px");
    padding: 4px 8px;
    height: calc(1.42857em);
    border: $normal-border;
    border-radius: 2px;
    font: inherit;
    color: #333333;
}

kendo-grid {
    input[type=text] {
        width: 80%;
    }
}

fibainputdate kendo-datepicker.k-datepicker {
    width: ($base-input-width + 16) + unquote("px");
}

/* Textareas */
textarea {
    width: 99%;
    height: 80px;
    border: $normal-border;
    border-radius: 2px;
    font-family: "Open Sans", Open Sans, Arial, Helvetica, sans-serif;
}

/* Numeric */
.k-numerictextbox {
    width: ($base-input-width + 18) + unquote("px");
}

/* Combobox */
.k-combobox {
    width: ($base-input-width + 18) + unquote("px");

    &.fiba-picker {
        width: ($base-input-width - 19) + unquote("px");
    }
}

/* Combobox */
fibaSelectItem.input-xs .k-combobox {
    width: (80 + 18) + unquote("px");

    &.fiba-picker {
        width: (80 - 19) + unquote("px");
    }
}

fibaInputTextNumeric.input-xs .k-numerictextbox {
    width: 80px;
}

kendo-combobox.no-arrow span.k-select {
    display: none;
}

kendo-combobox.no-arrow > span.k-dropdown-wrap > span.k-i-close {
    right: 8px;
}

/* Multiselect */
.k-multiselect {
    min-width: ($base-input-width + 17) + unquote("px");
    max-width: ($base-input-width + 17) + unquote("px");
}

/* extra large inputs */

.input-xl {
    input[type=text], input[type=password], input[type=email], input[type=url] {
        width: $base-input-xl-width + unquote("px");
    }

    fibainputdate input[type=text] {
        width: ($base-input-xl-width - 39) + unquote("px");
    }

    .k-numerictextbox {
        width: ($base-input-xl-width + 18) + unquote("px");
    }

    .k-combobox.fiba-picker {
        width: ($base-input-xl-width + 18) + unquote("px");
    }
    /* Multiselect */
    .k-multiselect {
        min-width: ($base-input-xl-width + 17) + unquote("px");
        max-width: ($base-input-xl-width + 17) + unquote("px");
    }

    .k-combobox {
        width: ($base-input-xl-width + 18) + unquote("px");

        &.fiba-select {
            width: ($base-input-xl-width - 19) + unquote("px");
        }
    }
}

.input-xxl {
    input[type=text], input[type=password], input[type=email], input[type=url] {
        width: $base-input-xxl-width + unquote("px");
    }

    fibainputdate input[type=text] {
        width: ($base-input-xxl-width - 39) + unquote("px");
    }

    .k-numerictextbox {
        width: ($base-input-xxl-width + 18) + unquote("px");
    }

    .k-combobox.fiba-picker {
        width: ($base-input-xxl-width + 18) + unquote("px");
    }
    /* Multiselect */
    .k-multiselect {
        min-width: ($base-input-xxl-width + 17) + unquote("px");
        max-width: ($base-input-xxl-width + 17) + unquote("px");
    }

    .k-combobox {
        width: ($base-input-xxl-width + 18) + unquote("px");

        &.fiba-select {
            width: ($base-input-xxl-width - 19) + unquote("px");
        }
    }
}

/* Validation */
.fiba-input.ng-invalid.ng-touched kendo-dialog input,
.fiba-input.ng-invalid.ng-touched kendo-dialog kendo-combobox.k-combobox > span.k-dropdown-wrap,
.fiba-input.ng-invalid.ng-touched kendo-dialog kendo-grid { /* Normal styles inside dialog */
    border: $normal-border;
    color: $normal-color;
}

.fiba-input.ng-invalid.ng-touched input,
.fiba-input.ng-invalid.ng-touched kendo-combobox.k-combobox > span.k-dropdown-wrap,
.fiba-input.ng-invalid.ng-touched kendo-grid,
.fiba-input.ng-invalid.ng-touched kendo-dialog .fiba-input.ng-invalid.ng-touched input, /* Error styles inside dialog */
.fiba-input.ng-invalid.ng-touched kendo-dialog .fiba-input.ng-invalid.ng-touched kendo-combobox.k-combobox > span.k-dropdown-wrap,
.fiba-input.ng-invalid.ng-touched kendo-dialog .fiba-input.ng-invalid.ng-touched kendo-grid {
    color: #f5503e;
    border-color: rgba(245, 80, 62, .5);
}

.validation-errors, .validation-form-errors {
    display: none;
}

.validation-errors ul, .validation-form-errors ul {
    color: #f5503e;
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 0.8em;
}

.fiba-input.ng-invalid.ng-touched kendo-dialog .validation-errors {
    display: none;
}

.fiba-input.ng-invalid.ng-touched .validation-errors,
.fiba-input.ng-invalid.ng-touched kendo-dialog .fiba-input.ng-invalid.ng-touched .validation-errors {
    display: block;
}

form.ng-invalid.ng-touched kendo-dialog .validation-form-errors {
    display: none;
}

form.ng-invalid.ng-touched .validation-form-errors,
form.ng-invalid.ng-touched kendo-dialog form.ng-invalid.ng-touched .validation-form-errors {
    display: block;
}

.fiba-input[required] kendo-dialog input,
.fiba-input[required] kendo-dialog kendo-grid-toolbar {
    background-image: none;
}

.fiba-input[required] input,
.fiba-input[required] kendo-dialog .fiba-input[required] input,
.fiba-input[required] textarea,
.fiba-input[required] kendo-grid-toolbar,
.fiba-input[required] kendo-dialog .fiba-input[required] kendo-grid-toolbar { /* Manage inputs nested in required pickers */
    background-size: 8px 8px;
    background: radial-gradient(circle at 4px 4px, rgba(245, 80, 62, .5) 2px, #FFFFFF 2px) no-repeat left top;
}

.fiba-input[required] input:disabled {
    background: radial-gradient(circle at 4px 4px, rgba(245, 80, 62, .5) 2px, rgb(235, 235, 228) 2px) no-repeat left top;
}

fibainputdatetime.fiba-input kendo-datepicker.date {
    width: 150px;
}

fibainputdatetime.fiba-input kendo-dateinput.time {
    width: 100px;
}

fibaInputDateTimeTmConfiguration.fiba-input kendo-dateinput.time {
    width: 100px;
}

fibatextnumbertotime.input-element input {
    width: 60px;
}

.k-grid-edit-row .k-widget, .k-grid-edit-row .k-button, .k-grid-edit-row .k-textbox {
    margin-top: auto;
    margin-bottom: auto;
}

.k-grid tr.warning {
    background-color: #DDDDDD;
}

.k-dialog {
    max-width: 60%;
}

.k-button.validate-completely span {
    color: darkseagreen;
}

.k-button.validate-partially span {
    color: #ffb347;
}

.k-button.invalidate span {
    color: lightgray;
}

.k-button.locked span {
    color: darkred;
}

.k-button.unlocked span {
    color: darkseagreen;
}

.k-dropdown-button.k-primary > .k-button .k-button.bg-gold, .k-dropdown-button.bg-gold > .k-button {
    color: #ffffff !important;
    border-color: #b89d54 !important;
    background: #b89d54 linear-gradient(rgba(184, 180, 84, 0), rgba(184, 180, 84, 0.2)) !important;
}

/* End */

fibaGridCompetitionTeamDelegation {
    kendo-grid {
        .k-grid-header th {
            padding: 8px 4px;
            white-space: normal;
            text-align: center;

            span {
                text-align: center;
            }
        }
    }
}

.k-grid.no-scrollbar.overflow-visible .k-grid-content {
    overflow: visible;
}

.k-grid.no-scrollbar.overflow-visible .k-grid-container {
    overflow: visible;
}

fibapickerperson kendo-combobox.k-combobox {
    width: 179px;
}

.k-dropdown-button.k-widget.k-bare {
    border-color: transparent !important;
    color: inherit;
    background: none !important;
    box-shadow: none !important;
    position: relative;
    transition: color .2s ease-in-out;
}

.k-dropdown-button.k-widget.k-bare .k-button {
    border-color: transparent !important;
    color: inherit;
    background: none !important;
    box-shadow: none !important;
    position: relative;
    transition: color .2s ease-in-out;
}

.k-dropdown-button.k-widget.bg-gold {
    border-radius: 2px;
}

.k-grid td .centered {
    text-align: center;
}
