@use "sass:map";
@use "sass:math";
@use "../core/_index.scss" as *;
@use "../core/functions/index.import.scss" as *;

// TreeView

/// The font family of the TreeView.
/// @group treeview
$kendo-treeview-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the TreeView.
/// @group treeview
$kendo-treeview-font-size: var( --kendo-font-size, inherit )  !default;
/// The font size of the small TreeView.
/// @group treeview
$kendo-treeview-sm-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The font size of the medium TreeView.
/// @group treeview
$kendo-treeview-md-font-size: var( --kendo-font-size, inherit )  !default;
/// The font size of the large TreeView.
/// @group treeview
$kendo-treeview-lg-font-size: var( --kendo-font-size, inherit )  !default;
/// The line height of the TreeView.
/// @group treeview
$kendo-treeview-line-height: var( --kendo-line-height, normal ) !default;
/// The line height of the small TreeView.
/// @group treeview
$kendo-treeview-sm-line-height: math.div( 20, 14 ) !default;
/// The line height of the medium TreeView.
/// @group treeview
$kendo-treeview-md-line-height: var( --kendo-line-height, normal ) !default;
/// The line height of the large TreeView.
/// @group treeview
$kendo-treeview-lg-line-height: var( --kendo-line-height, normal ) !default;

/// The indentation of child groups in the TreeView.
/// @group treeview
$kendo-treeview-indent: 24px !default;

/// The horizontal padding of the TreeView items.
/// @group treeview
$kendo-treeview-item-padding-x: k-spacing(3) !default;
/// The horizontal padding of the small TreeView items.
/// @group treeview
$kendo-treeview-sm-item-padding-x: k-spacing(2) !default;
/// The horizontal padding of the medium TreeView items.
/// @group treeview
$kendo-treeview-md-item-padding-x: k-spacing(3) !default;
/// The horizontal padding of the large TreeView items.
/// @group treeview
$kendo-treeview-lg-item-padding-x: k-spacing(3) !default;
/// The vertical padding of the TreeView items.
/// @group treeview
$kendo-treeview-item-padding-y: k-spacing(1) !default;
/// The vertical padding of the small TreeView items.
/// @group treeview
$kendo-treeview-sm-item-padding-y: k-spacing(1) !default;
/// The vertical padding of the medium TreeView items.
/// @group treeview
$kendo-treeview-md-item-padding-y: k-spacing(1) !default;
/// The vertical padding of the large TreeView items.
/// @group treeview
$kendo-treeview-lg-item-padding-y: k-spacing(2) !default;
/// The border width of the TreeView items.
/// @group treeview
$kendo-treeview-item-border-width: 0px !default;
/// The border radius of the TreeView items.
/// @group treeview
$kendo-treeview-item-border-radius: k-spacing(1) !default;

/// The horizontal padding of the checkbox in the small TreeView.
/// @group treeview
$kendo-treeview-sm-checkbox-padding-x: k-spacing(1) !default;
/// The horizontal padding of the checkbox in the medium TreeView.
/// @group treeview
$kendo-treeview-md-checkbox-padding-x: $kendo-treeview-sm-checkbox-padding-x !default;
/// The horizontal padding of the checkbox in the large TreeView.
/// @group treeview
$kendo-treeview-lg-checkbox-padding-x: $kendo-treeview-sm-checkbox-padding-x !default;
/// The vertical padding of the checkbox in the small TreeView.
/// @group treeview
$kendo-treeview-sm-checkbox-padding-y: k-spacing(0.5) !default;
/// The vertical padding of the checkbox in the medium TreeView.
/// @group treeview
$kendo-treeview-md-checkbox-padding-y: unset !default;
/// The vertical padding of the checkbox in the large TreeView.
/// @group treeview
$kendo-treeview-lg-checkbox-padding-y: null !default;


/// The sizes map of the TreeView.
/// @group treeview
$kendo-treeview-sizes: (
    sm: (
        font-size: $kendo-treeview-sm-font-size,
        line-height: $kendo-treeview-sm-line-height,
        item-padding-x: $kendo-treeview-sm-item-padding-x,
        item-padding-y: $kendo-treeview-sm-item-padding-y,
        checkbox-padding-x: $kendo-treeview-sm-checkbox-padding-x,
        checkbox-padding-y: $kendo-treeview-sm-checkbox-padding-y
    ),
    md: (
        font-size: $kendo-treeview-md-font-size,
        line-height: $kendo-treeview-md-line-height,
        item-padding-x: $kendo-treeview-md-item-padding-x,
        item-padding-y: $kendo-treeview-md-item-padding-y,
        checkbox-padding-x: $kendo-treeview-md-checkbox-padding-x,
        checkbox-padding-y: $kendo-treeview-md-checkbox-padding-y
    ),
    lg: (
        font-size: $kendo-treeview-lg-font-size,
        line-height: $kendo-treeview-lg-line-height,
        item-padding-x: $kendo-treeview-lg-item-padding-x,
        item-padding-y: $kendo-treeview-lg-item-padding-y,
        checkbox-padding-x: $kendo-treeview-lg-checkbox-padding-x,
        checkbox-padding-y: $kendo-treeview-lg-checkbox-padding-y
    )
) !default;


/// The background color of the TreeView.
/// @group treeview
$kendo-treeview-bg: null !default;
/// The text color of the TreeView.
/// @group treeview
$kendo-treeview-text: $kendo-component-text !default;
/// The border color of the TreeView.
/// @group treeview
$kendo-treeview-border: null !default;

/// The background color of hovered TreeView items.
/// @group treeview
$kendo-treeview-item-hover-bg: $kendo-hover-bg !default;
/// The text color of hovered TreeView items.
/// @group treeview
$kendo-treeview-item-hover-text: $kendo-hover-text !default;
/// The border color of hovered TreeView items.
/// @group treeview
$kendo-treeview-item-hover-border: $kendo-treeview-item-hover-bg !default;
/// The background gradient of hovered TreeView items.
/// @group treeview
$kendo-treeview-item-hover-gradient: null !default;

/// The background color of selected TreeView items.
/// @group treeview
$kendo-treeview-item-selected-bg: $kendo-selected-bg !default;
/// The text color of selected TreeView items.
/// @group treeview
$kendo-treeview-item-selected-text: $kendo-selected-text !default;
/// The border color of selected TreeView items.
/// @group treeview
$kendo-treeview-item-selected-border: $kendo-treeview-item-selected-bg !default;
/// The background gradient of selected TreeView items.
/// @group treeview
$kendo-treeview-item-selected-gradient: null !default;

/// The box shadow of focused TreeView items.
/// @group treeview
$kendo-treeview-item-focus-shadow: inset 0 0 0 2px if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 15%, transparent), rgba( $kendo-treeview-text, .15 )) !default;

/// The background color of the Load More button in the TreeView.
/// @group treeview
$kendo-treeview-loadmore-bg: transparent !default;
/// The text color of the Load More button in the TreeView.
/// @group treeview
$kendo-treeview-loadmore-text: $kendo-link-text !default;
/// The border color of the Load More button in the TreeView.
/// @group treeview
$kendo-treeview-loadmore-border: null !default;

/// The background color of the hovered Load More button in the TreeView.
/// @group treeview
$kendo-treeview-loadmore-hover-bg: transparent !default;
/// The text color of the hovered Load More button in the TreeView.
/// @group treeview
$kendo-treeview-loadmore-hover-text: $kendo-link-hover-text !default;
/// The border color of the hovered Load More button in the TreeView.
/// @group treeview
$kendo-treeview-loadmore-hover-border: null !default;

/// The background color of the focused Load More button in the TreeView.
/// @group treeview
$kendo-treeview-loadmore-focus-bg: transparent !default;
/// The text color of the focused Load More button in the TreeView.
/// @group treeview
$kendo-treeview-loadmore-focus-text: $kendo-link-hover-text !default;
/// The border color of the focused Load More button in the TreeView.
/// @group treeview
$kendo-treeview-loadmore-focus-border: null !default;
/// The box shadow of the focused Load More button in the TreeView.
/// @group treeview
$kendo-treeview-loadmore-focus-shadow: $kendo-treeview-item-focus-shadow !default;



@forward "@progress/kendo-theme-default/scss/treeview/_variables.scss" with (
    $kendo-treeview-font-family: $kendo-treeview-font-family,
    $kendo-treeview-font-size: $kendo-treeview-font-size,
    $kendo-treeview-sm-font-size: $kendo-treeview-sm-font-size,
    $kendo-treeview-md-font-size: $kendo-treeview-md-font-size,
    $kendo-treeview-lg-font-size: $kendo-treeview-lg-font-size,
    $kendo-treeview-line-height: $kendo-treeview-line-height,
    $kendo-treeview-sm-line-height: $kendo-treeview-sm-line-height,
    $kendo-treeview-md-line-height: $kendo-treeview-md-line-height,
    $kendo-treeview-lg-line-height: $kendo-treeview-lg-line-height,
    $kendo-treeview-indent: $kendo-treeview-indent,
    $kendo-treeview-item-padding-x: $kendo-treeview-item-padding-x,
    $kendo-treeview-sm-item-padding-x: $kendo-treeview-sm-item-padding-x,
    $kendo-treeview-md-item-padding-x: $kendo-treeview-md-item-padding-x,
    $kendo-treeview-lg-item-padding-x: $kendo-treeview-lg-item-padding-x,
    $kendo-treeview-item-padding-y: $kendo-treeview-item-padding-y,
    $kendo-treeview-sm-item-padding-y: $kendo-treeview-sm-item-padding-y,
    $kendo-treeview-md-item-padding-y: $kendo-treeview-md-item-padding-y,
    $kendo-treeview-lg-item-padding-y: $kendo-treeview-lg-item-padding-y,
    $kendo-treeview-item-border-width: $kendo-treeview-item-border-width,
    $kendo-treeview-item-border-radius: $kendo-treeview-item-border-radius,
    $kendo-treeview-sm-checkbox-padding-x: $kendo-treeview-sm-checkbox-padding-x,
    $kendo-treeview-md-checkbox-padding-x: $kendo-treeview-md-checkbox-padding-x,
    $kendo-treeview-lg-checkbox-padding-x: $kendo-treeview-lg-checkbox-padding-x,
    $kendo-treeview-sm-checkbox-padding-y: $kendo-treeview-sm-checkbox-padding-y,
    $kendo-treeview-md-checkbox-padding-y: $kendo-treeview-md-checkbox-padding-y,
    $kendo-treeview-lg-checkbox-padding-y: $kendo-treeview-lg-checkbox-padding-y,
    $kendo-treeview-sizes: $kendo-treeview-sizes,
    $kendo-treeview-bg: $kendo-treeview-bg,
    $kendo-treeview-text: $kendo-treeview-text,
    $kendo-treeview-border: $kendo-treeview-border,
    $kendo-treeview-item-hover-bg: $kendo-treeview-item-hover-bg,
    $kendo-treeview-item-hover-text: $kendo-treeview-item-hover-text,
    $kendo-treeview-item-hover-border: $kendo-treeview-item-hover-border,
    $kendo-treeview-item-hover-gradient: $kendo-treeview-item-hover-gradient,
    $kendo-treeview-item-selected-bg: $kendo-treeview-item-selected-bg,
    $kendo-treeview-item-selected-text: $kendo-treeview-item-selected-text,
    $kendo-treeview-item-selected-border: $kendo-treeview-item-selected-border,
    $kendo-treeview-item-selected-gradient: $kendo-treeview-item-selected-gradient,
    $kendo-treeview-item-focus-shadow: $kendo-treeview-item-focus-shadow,
    $kendo-treeview-loadmore-bg: $kendo-treeview-loadmore-bg,
    $kendo-treeview-loadmore-text: $kendo-treeview-loadmore-text,
    $kendo-treeview-loadmore-border: $kendo-treeview-loadmore-border,
    $kendo-treeview-loadmore-hover-bg: $kendo-treeview-loadmore-hover-bg,
    $kendo-treeview-loadmore-hover-text: $kendo-treeview-loadmore-hover-text,
    $kendo-treeview-loadmore-hover-border: $kendo-treeview-loadmore-hover-border,
    $kendo-treeview-loadmore-focus-bg: $kendo-treeview-loadmore-focus-bg,
    $kendo-treeview-loadmore-focus-text: $kendo-treeview-loadmore-focus-text,
    $kendo-treeview-loadmore-focus-border: $kendo-treeview-loadmore-focus-border,
    $kendo-treeview-loadmore-focus-shadow: $kendo-treeview-loadmore-focus-shadow
);
