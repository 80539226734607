@use "../core/_index.scss" as *;

// Actions
$kendo-actions-margin-top: k-spacing(4) !default;
$kendo-actions-padding-x: k-spacing(2) !default;
$kendo-actions-padding-y: k-spacing(2) !default;
$kendo-actions-border-width: 0px !default;
$kendo-actions-button-spacing: k-spacing(2) !default;

$kendo-actions-bg: null !default;
$kendo-actions-text: null !default;
$kendo-actions-border: null !default;
$kendo-actions-gradient: null !default;


@forward "@progress/kendo-theme-default/scss/action-buttons/_variables.scss" with (
    $kendo-actions-margin-top: $kendo-actions-margin-top,
    $kendo-actions-padding-x: $kendo-actions-padding-x,
    $kendo-actions-padding-y: $kendo-actions-padding-y,
    $kendo-actions-border-width: $kendo-actions-border-width,
    $kendo-actions-button-spacing: $kendo-actions-button-spacing,
    $kendo-actions-bg: $kendo-actions-bg,
    $kendo-actions-text: $kendo-actions-text,
    $kendo-actions-border: $kendo-actions-border,
    $kendo-actions-gradient: $kendo-actions-gradient
);
