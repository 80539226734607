@use "../core/_index.scss" as *;
@use "../input/_variables.scss" as *;

// Floating label

/// The transformation scale of the Floating Label.
/// @group floating-label
$kendo-floating-label-scale: 1 !default;
/// The font size of the Floating Label.
/// @group floating-label
$kendo-floating-label-font-size: $kendo-input-font-size !default;
/// The maximum width of the Floating Label.
/// @group floating-label
$kendo-floating-label-max-width: 90% !default;
/// The line height of the Floating Label.
/// @group floating-label
$kendo-floating-label-line-height: $kendo-input-line-height !default;
/// The height of the Floating Label.
/// @group floating-label
$kendo-floating-label-height: calc( #{$kendo-floating-label-line-height} * #{$kendo-floating-label-font-size} ) !default;
/// The horizontal offset of the Floating Label.
/// @group floating-label
$kendo-floating-label-offset-x: calc( #{$kendo-input-padding-x} + #{$kendo-input-border-width} ) !default;
/// The vertical offset of the Floating Label.
/// @group floating-label
$kendo-floating-label-offset-y: calc( #{$kendo-floating-label-height} + #{$kendo-input-border-width} + #{$kendo-input-padding-y} ) !default;

/// The transformation scale of the focused Floating Label.
/// @group floating-label
$kendo-floating-label-focus-scale: 1 !default;
/// The horizontal offset of the focused Floating Label.
/// @group floating-label
$kendo-floating-label-focus-offset-x: 0 !default;
/// The vertical offset of the focused Floating Label.
/// @group floating-label
$kendo-floating-label-focus-offset-y: 0 !default;

/// The transition of the Floating Label.
/// @group floating-label
$kendo-floating-label-transition: .2s ease-out !default;

/// The background color of the Floating Label.
/// @group floating-label
$kendo-floating-label-bg: null !default;
/// The text color of the Floating Label.
/// @group floating-label
$kendo-floating-label-text: null !default;

/// The background color of the focused Floating Label.
/// @group floating-label
$kendo-floating-label-focus-bg: null !default;
/// The text color of the focused Floating Label.
/// @group floating-label
$kendo-floating-label-focus-text: null !default;


@forward "@progress/kendo-theme-default/scss/floating-label/_variables.scss" with (
    $kendo-floating-label-scale: $kendo-floating-label-scale,
    $kendo-floating-label-font-size: $kendo-floating-label-font-size,
    $kendo-floating-label-max-width: $kendo-floating-label-max-width,
    $kendo-floating-label-line-height: $kendo-floating-label-line-height,
    $kendo-floating-label-height: $kendo-floating-label-height,
    $kendo-floating-label-offset-x: $kendo-floating-label-offset-x,
    $kendo-floating-label-offset-y: $kendo-floating-label-offset-y,
    $kendo-floating-label-focus-scale: $kendo-floating-label-focus-scale,
    $kendo-floating-label-focus-offset-x: $kendo-floating-label-focus-offset-x,
    $kendo-floating-label-focus-offset-y: $kendo-floating-label-focus-offset-y,
    $kendo-floating-label-transition: $kendo-floating-label-transition,
    $kendo-floating-label-bg: $kendo-floating-label-bg,
    $kendo-floating-label-text: $kendo-floating-label-text,
    $kendo-floating-label-focus-bg: $kendo-floating-label-focus-bg,
    $kendo-floating-label-focus-text: $kendo-floating-label-focus-text
);
