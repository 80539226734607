@use "../core/_index.scss" as *;
@use "@progress/kendo-theme-default/scss/scheduler/_layout.scss" as *;


@mixin kendo-scheduler--layout() {

    @include kendo-scheduler--layout-base();

    // Appointments
    kendo-scheduler .k-event,
    .k-event {
        &::before {
            display: none;
            left: 0;
        }

        // Angular fix for stronger selector and smaller padding
        .k-event-template {
            & { // stylelint-disable-line
                padding-block: k-spacing(1);
                padding-inline: k-spacing(2);
                font-size: var( --kendo-font-size-sm, inherit );
            }
        }

        .k-event-actions {
            right: k-spacing(2);

            &:first-child {
                margin: .1ex .4ex 0 k-spacing(2);
            }
        }
    }

    .k-rtl {
        kendo-scheduler .k-event,
        .k-event {
            &::before {
                right: 0;
            }
        }
    }
}
