@use "../core/_index.scss" as *;
@use "@progress/kendo-theme-default/scss/treeview/_layout.scss" as *;


@mixin kendo-treeview--layout() {

    @include kendo-treeview--layout-base();

    // Treeview
    .k-treeview {}


    // Treeview leaf
    .k-treeview-leaf {
        transition: $kendo-transition;
    }

}
