@use "../card/_variables.scss" as *;
@use "@progress/kendo-theme-default/scss/timeline/_layout.scss" as *;


@mixin kendo-timeline--layout() {

    @include kendo-timeline--layout-base();

    .k-timeline-card .k-card-header {
        padding-top: $kendo-card-header-padding-x;
    }

}
