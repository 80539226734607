@use "../core/_index.scss" as *;
// Dropdowntree

/// The horizontal padding of the DropdownTree popup
/// @group dropdowntree
$kendo-dropdowntree-popup-padding-x: k-spacing(2) !default;
/// The vertical padding of the DropdownTree popup
/// @group dropdowntree
$kendo-dropdowntree-popup-padding-y: k-spacing(2) !default;


@forward "@progress/kendo-theme-default/scss/dropdowntree/_variables.scss" with (
    $kendo-dropdowntree-popup-padding-x: $kendo-dropdowntree-popup-padding-x,
    $kendo-dropdowntree-popup-padding-y: $kendo-dropdowntree-popup-padding-y
);
