@use "../core/_index.scss" as *;
@use "@progress/kendo-theme-default/scss/checkbox/_layout.scss" as *;


@mixin kendo-checkbox--layout() {

    @include kendo-checkbox--layout-base();

    // Override inherited bootstrap border radius
    .k-checkbox.k-rounded-sm {
        border-radius: calc( #{k-border-radius(sm)} / 2 );
    }
    .k-checkbox.k-rounded-md {
        border-radius: k-border-radius(sm);
    }
    .k-checkbox.k-rounded-lg {
        border-radius: k-border-radius(md);
    }

}
