@use "../core/_index.scss" as *;
@use "./_variables.scss" as *;
@use "@progress/kendo-theme-default/scss/input/_layout.scss" as *;

@mixin kendo-input--layout() {

    @include kendo-input--layout-base();

    // Input, Textbox
    .k-textbox {
        transition: $kendo-transition;
    }

}
