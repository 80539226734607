@use "../core/_index.scss" as *;
@use "../button/_variables.scss" as *;
@use "../core/functions/index.import.scss" as *;

// Calendar

/// The width of the border around the Calendar.
/// @group calendar
$kendo-calendar-border-width: 1px !default;
/// The font family of the Calendar.
/// @group calendar
$kendo-calendar-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Calendar.
/// @group calendar
$kendo-calendar-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Calendar.
/// @group calendar
$kendo-calendar-line-height: var( --kendo-line-height, normal ) !default;

/// The size of the cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-size: 38px !default;
/// The background color of the Calendar.
/// @group calendar
$kendo-calendar-bg: $kendo-component-bg !default;
/// The text color of the Calendar.
/// @group calendar
$kendo-calendar-text: $kendo-component-text !default;
/// The border color of the Calendar.
/// @group calendar
$kendo-calendar-border: $kendo-component-border !default;

/// The horizontal padding of the header in the Calendar.
/// @group calendar
$kendo-calendar-header-padding-x: k-spacing(1) !default;
/// The vertical padding of the header in the Calendar.
/// @group calendar
$kendo-calendar-header-padding-y: k-spacing(1) !default;
/// The border width of the header in the Calendar.
/// @group calendar
$kendo-calendar-header-border-width: 1px !default;

/// The background color of the header in the Calendar.
/// @group calendar
$kendo-calendar-header-bg: $kendo-component-header-bg !default;
/// The text color of the header in the Calendar.
/// @group calendar
$kendo-calendar-header-text: $kendo-component-header-text !default;
/// The border color of the header in the Calendar.
/// @group calendar
$kendo-calendar-header-border: $kendo-component-header-border !default;
/// The gradient of the header in the Calendar.
/// @group calendar
$kendo-calendar-header-gradient: $kendo-component-header-gradient !default;
/// The box shadow of the header in the Calendar.
/// @group calendar
$kendo-calendar-header-shadow: none !default;

/// The gap between the items in the navigation of the Calendar.
/// @group calendar
$kendo-calendar-nav-gap: null !default;

/// The horizontal padding of the footer in the Calendar.
/// @group calendar
$kendo-calendar-footer-padding-x: k-spacing(4) !default;
/// The vertical padding of the footer in the Calendar.
/// @group calendar
$kendo-calendar-footer-padding-y: k-spacing(2) !default;

/// The horizontal padding of the cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-padding-x: k-spacing(1) !default;
/// The vertical padding of the cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-padding-y: k-spacing(1) !default;
/// The line height of the cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-line-height: $kendo-calendar-line-height !default;

/// The border radius of the cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-border-radius: k-border-radius(md) !default;

/// The horizontal padding of the header cells in the Calendar.
/// @group calendar
$kendo-calendar-header-cell-padding-x: null !default;
/// The vertical padding of the header cells in the Calendar.
/// @group calendar
$kendo-calendar-header-cell-padding-y: null !default;
/// The width of the header cells in the Calendar.
/// @group calendar
$kendo-calendar-header-cell-width: $kendo-calendar-cell-size !default;
/// The height of the header cells in the Calendar.
/// @group calendar
$kendo-calendar-header-cell-height: $kendo-calendar-cell-size !default;
/// The font size of the header cells in the Calendar.
/// @group calendar
$kendo-calendar-header-cell-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The line height of the header cells in the Calendar.
/// @group calendar
$kendo-calendar-header-cell-line-height: var( --kendo-line-height-lg, normal ) !default;

/// The background color of the header cells in the Calendar.
/// @group calendar
$kendo-calendar-header-cell-bg: null !default;
/// The text color of the header cells in the Calendar.
/// @group calendar
$kendo-calendar-header-cell-text: if($kendo-enable-color-system, k-color( subtle ) , $kendo-subtle-text) !default;
/// The opacity of the header cells in the Calendar.
/// @group calendar
$kendo-calendar-header-cell-opacity: null !default;

/// The horizontal padding of the caption in the Calendar.
/// @group calendar
$kendo-calendar-caption-padding-x: k-spacing(3) !default;
/// The vertical padding of the caption in the Calendar.
/// @group calendar
$kendo-calendar-caption-padding-y: k-spacing(1) !default;
/// The height of the caption in the Calendar.
/// @group calendar
$kendo-calendar-caption-height: $kendo-calendar-cell-size !default;
/// The font size of the caption in the Calendar.
/// @group calendar
$kendo-calendar-caption-font-size: null !default;
/// The line height of the caption in the Calendar.
/// @group calendar
$kendo-calendar-caption-line-height: null !default;
/// The font weight of the caption in the Calendar.
/// @group calendar
$kendo-calendar-caption-font-weight: bold !default;

/// The font size of the week number cells in the Calendar.
/// @group calendar
$kendo-calendar-week-number-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The width of the Calendar view.
/// @group calendar
$kendo-calendar-view-width: ($kendo-calendar-cell-size * 8) !default;
/// The height of the Calendar view.
/// @group calendar
$kendo-calendar-view-height: ($kendo-calendar-cell-size * 7) !default;

/// The gap between the items in the Calendar view.
/// @group calendar
$kendo-calendar-view-gap: k-spacing(4) !default;

/// The background color of the weekend cells in the Calendar.
/// @group calendar
$kendo-calendar-weekend-bg: null !default;
/// The text color of the weekend cells in the Calendar.
/// @group calendar
$kendo-calendar-weekend-text: null !default;

/// The style of the current day in the Calendar.
/// @group calendar
$kendo-calendar-today-style: box !default;
/// The color of the current day in the Calendar.
/// @group calendar
$kendo-calendar-today-color: $kendo-color-primary !default;

/// The background color of the week number cells in the Calendar.
/// @group calendar
$kendo-calendar-week-number-bg: null !default;
/// The text color of the week number cells in the Calendar.
/// @group calendar
$kendo-calendar-week-number-text: $kendo-calendar-header-cell-text !default;

/// The background color of the preceding/subsequent month cells in the Calendar.
/// @group calendar
$kendo-calendar-other-month-bg: null !default;
/// The text color of the preceding/subsequent month cells in the Calendar.
/// @group calendar
$kendo-calendar-other-month-text: $kendo-calendar-header-cell-text !default;

/// The background color of the cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-bg: null !default;
/// The text color of the cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-text: null !default;
/// The border color of the cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-border: null !default;
/// The gradient of the cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-gradient: null !default;

/// The background color of the hovered cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-hover-bg: $kendo-hover-bg !default;
/// The text color of the hovered cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-hover-text: $kendo-hover-text !default;
/// The border color of the hovered cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-hover-border: $kendo-hover-border !default;
/// The gradient of the hovered cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-hover-gradient: null !default;

/// The background color of the selected cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-selected-bg: $kendo-selected-bg !default;
/// The text color of the selected cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-selected-text: $kendo-selected-text !default;
/// The border color of the selected cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-selected-border: $kendo-selected-border !default;
/// The gradient of the selected cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-selected-gradient: null !default;

/// The background color of the selected and hovered cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-selected-hover-bg: $kendo-selected-hover-bg !default;
/// The text color of the selected and hovered cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-selected-hover-text: $kendo-selected-hover-text !default;
/// The border color of the selected and hovered cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-selected-hover-border: $kendo-selected-hover-border !default;
/// The gradient of the selected and hovered cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-selected-hover-gradient: null !default;

/// The shadow of the focused cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-focus-shadow: inset $kendo-button-focus-shadow !default;
/// The shadow of the selected and focused cells in the Calendar.
/// @group calendar
$kendo-calendar-cell-selected-focus-shadow: inset 0 0 0 2px if($kendo-enable-color-system, color-mix(in srgb, k-color( primary-active ) 50%, transparent), rgba( k-color-darken( $kendo-color-primary, 12.5% ), .5 )) !default;


/// The width of the Calendar navigation.
/// @group calendar
$kendo-calendar-navigation-width: 5em !default;
/// The height of the items in the Calendar navigation.
/// @group calendar
$kendo-calendar-navigation-item-height: 2em !default;

/// The background color of the Calendar navigation.
/// @group calendar
$kendo-calendar-navigation-bg: $kendo-calendar-header-bg !default;
/// The text color of the Calendar navigation.
/// @group calendar
$kendo-calendar-navigation-text: $kendo-calendar-header-text !default;
/// The border color of the Calendar navigation.
/// @group calendar
$kendo-calendar-navigation-border: $kendo-calendar-header-border !default;

/// The text color of the hovered items in the Calendar navigation.
/// @group calendar
$kendo-calendar-navigation-hover-text: $kendo-link-hover-text !default;


/// The horizontal padding of the header in the Infinite Calendar.
/// @group calendar
$kendo-infinite-calendar-header-padding-x: k-spacing(4) !default;
/// The vertical padding of the header in the Infinite Calendar.
/// @group calendar
$kendo-infinite-calendar-header-padding-y: k-spacing(2) !default;

/// The horizontal padding of the Calendar view in the Infinite Calendar.
/// @group calendar
$kendo-infinite-calendar-view-padding-x: k-spacing(4) !default;
/// The vertical padding of the Calendar view in the Infinite Calendar.
/// @group calendar
$kendo-infinite-calendar-view-padding-y: k-spacing(0) !default;
/// The height of the Calendar view in the Infinite Calendar.
/// @group calendar
$kendo-infinite-calendar-view-height: ( $kendo-calendar-cell-size * 9 ) !default;


// Multiview calendar

/// The border radius of the range cells in the Multiview Calendar.
/// @group calendar
$kendo-calendar-range-cell-border-radius: k-border-radius(lg) !default;

// Calendar sizes

/// The font size of the small Calendar.
/// @group calendar
$kendo-calendar-sm-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the small Calendar.
/// @group calendar
$kendo-calendar-sm-line-height: var( --kendo-line-height, normal ) !default;
/// The size of the cells in the small Calendar.
/// @group calendar
$kendo-calendar-sm-cell-size: 34px !default;
/// The horizontal padding of the cells in the small Calendar.
/// @group calendar
$kendo-calendar-sm-cell-padding-x: k-spacing(0.5) !default;
/// The vertical padding of the cells in the small Calendar.
/// @group calendar
$kendo-calendar-sm-cell-padding-y: k-spacing(0.5) !default;

/// The font size of the medium Calendar.
/// @group calendar
$kendo-calendar-md-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the medium Calendar.
/// @group calendar
$kendo-calendar-md-line-height: var( --kendo-line-height, normal ) !default;
/// The size of the cells in the medium Calendar.
/// @group calendar
$kendo-calendar-md-cell-size: 38px !default;
/// The horizontal padding of the cells in the medium Calendar.
/// @group calendar
$kendo-calendar-md-cell-padding-x: k-spacing(1) !default;
/// The vertical padding of the cells in the medium Calendar.
/// @group calendar
$kendo-calendar-md-cell-padding-y: k-spacing(1) !default;

/// The font size of the large Calendar.
/// @group calendar
$kendo-calendar-lg-font-size: var( --kendo-font-size-lg, inherit ) !default;
/// The line height of the large Calendar.
/// @group calendar
$kendo-calendar-lg-line-height: var( --kendo-line-height-lg, normal ) !default;
/// The size of the cells in the large Calendar.
/// @group calendar
$kendo-calendar-lg-cell-size: 42px !default;
/// The horizontal padding of the cells in the large Calendar.
/// @group calendar
$kendo-calendar-lg-cell-padding-x: k-spacing(1) !default;
/// The vertical padding of the cells in the large Calendar.
/// @group calendar
$kendo-calendar-lg-cell-padding-y: k-spacing(1) !default;

/// The sizes Map of the Calendar.
/// @group calendar
$kendo-calendar-sizes: (
    sm: (
        font-size: $kendo-calendar-sm-font-size,
        line-height: $kendo-calendar-sm-line-height,
        cell-size: $kendo-calendar-sm-cell-size,
        cell-padding-x: $kendo-calendar-sm-cell-padding-y,
        cell-padding-y: $kendo-calendar-sm-cell-padding-x
    ),
    md: (
        font-size: $kendo-calendar-md-font-size,
        line-height: $kendo-calendar-md-line-height,
        cell-size: $kendo-calendar-md-cell-size,
        cell-padding-x: $kendo-calendar-md-cell-padding-y,
        cell-padding-y: $kendo-calendar-md-cell-padding-x
    ),
    lg: (
        font-size: $kendo-calendar-lg-font-size,
        line-height: $kendo-calendar-lg-line-height,
        cell-size: $kendo-calendar-lg-cell-size,
        cell-padding-x: $kendo-calendar-lg-cell-padding-y,
        cell-padding-y: $kendo-calendar-lg-cell-padding-x
    )
) !default;


@forward "@progress/kendo-theme-default/scss/calendar/_variables.scss" with (
    $kendo-calendar-border-width: $kendo-calendar-border-width,
    $kendo-calendar-font-family: $kendo-calendar-font-family,
    $kendo-calendar-font-size: $kendo-calendar-font-size,
    $kendo-calendar-line-height: $kendo-calendar-line-height,
    $kendo-calendar-cell-size: $kendo-calendar-cell-size,
    $kendo-calendar-bg: $kendo-calendar-bg,
    $kendo-calendar-text: $kendo-calendar-text,
    $kendo-calendar-border: $kendo-calendar-border,
    $kendo-calendar-header-padding-x: $kendo-calendar-header-padding-x,
    $kendo-calendar-header-padding-y: $kendo-calendar-header-padding-y,
    $kendo-calendar-header-border-width: $kendo-calendar-header-border-width,
    $kendo-calendar-header-bg: $kendo-calendar-header-bg,
    $kendo-calendar-header-text: $kendo-calendar-header-text,
    $kendo-calendar-header-border: $kendo-calendar-header-border,
    $kendo-calendar-header-gradient: $kendo-calendar-header-gradient,
    $kendo-calendar-header-shadow: $kendo-calendar-header-shadow,
    $kendo-calendar-nav-gap: $kendo-calendar-nav-gap,
    $kendo-calendar-footer-padding-x: $kendo-calendar-footer-padding-x,
    $kendo-calendar-footer-padding-y: $kendo-calendar-footer-padding-y,
    $kendo-calendar-cell-padding-x: $kendo-calendar-cell-padding-x,
    $kendo-calendar-cell-padding-y: $kendo-calendar-cell-padding-y,
    $kendo-calendar-cell-line-height: $kendo-calendar-cell-line-height,
    $kendo-calendar-cell-border-radius: $kendo-calendar-cell-border-radius,
    $kendo-calendar-header-cell-padding-x: $kendo-calendar-header-cell-padding-x,
    $kendo-calendar-header-cell-padding-y: $kendo-calendar-header-cell-padding-y,
    $kendo-calendar-header-cell-width: $kendo-calendar-header-cell-width,
    $kendo-calendar-header-cell-height: $kendo-calendar-header-cell-height,
    $kendo-calendar-header-cell-font-size: $kendo-calendar-header-cell-font-size,
    $kendo-calendar-header-cell-line-height: $kendo-calendar-header-cell-line-height,
    $kendo-calendar-header-cell-bg: $kendo-calendar-header-cell-bg,
    $kendo-calendar-header-cell-text: $kendo-calendar-header-cell-text,
    $kendo-calendar-header-cell-opacity: $kendo-calendar-header-cell-opacity,
    $kendo-calendar-caption-padding-x: $kendo-calendar-caption-padding-x,
    $kendo-calendar-caption-padding-y: $kendo-calendar-caption-padding-y,
    $kendo-calendar-caption-height: $kendo-calendar-caption-height,
    $kendo-calendar-caption-font-size: $kendo-calendar-caption-font-size,
    $kendo-calendar-caption-line-height: $kendo-calendar-caption-line-height,
    $kendo-calendar-caption-font-weight: $kendo-calendar-caption-font-weight,
    $kendo-calendar-week-number-font-size: $kendo-calendar-week-number-font-size,
    $kendo-calendar-view-width: $kendo-calendar-view-width,
    $kendo-calendar-view-height: $kendo-calendar-view-height,
    $kendo-calendar-view-gap: $kendo-calendar-view-gap,
    $kendo-calendar-weekend-bg: $kendo-calendar-weekend-bg,
    $kendo-calendar-weekend-text: $kendo-calendar-weekend-text,
    $kendo-calendar-today-style: $kendo-calendar-today-style,
    $kendo-calendar-today-color: $kendo-calendar-today-color,
    $kendo-calendar-week-number-bg: $kendo-calendar-week-number-bg,
    $kendo-calendar-week-number-text: $kendo-calendar-week-number-text,
    $kendo-calendar-other-month-bg: $kendo-calendar-other-month-bg,
    $kendo-calendar-other-month-text: $kendo-calendar-other-month-text,
    $kendo-calendar-cell-bg: $kendo-calendar-cell-bg,
    $kendo-calendar-cell-text: $kendo-calendar-cell-text,
    $kendo-calendar-cell-border: $kendo-calendar-cell-border,
    $kendo-calendar-cell-gradient: $kendo-calendar-cell-gradient,
    $kendo-calendar-cell-hover-bg: $kendo-calendar-cell-hover-bg,
    $kendo-calendar-cell-hover-text: $kendo-calendar-cell-hover-text,
    $kendo-calendar-cell-hover-border: $kendo-calendar-cell-hover-border,
    $kendo-calendar-cell-hover-gradient: $kendo-calendar-cell-hover-gradient,
    $kendo-calendar-cell-selected-bg: $kendo-calendar-cell-selected-bg,
    $kendo-calendar-cell-selected-text: $kendo-calendar-cell-selected-text,
    $kendo-calendar-cell-selected-border: $kendo-calendar-cell-selected-border,
    $kendo-calendar-cell-selected-gradient: $kendo-calendar-cell-selected-gradient,
    $kendo-calendar-cell-selected-hover-bg: $kendo-calendar-cell-selected-hover-bg,
    $kendo-calendar-cell-selected-hover-text: $kendo-calendar-cell-selected-hover-text,
    $kendo-calendar-cell-selected-hover-border: $kendo-calendar-cell-selected-hover-border,
    $kendo-calendar-cell-selected-hover-gradient: $kendo-calendar-cell-selected-hover-gradient,
    $kendo-calendar-cell-focus-shadow: $kendo-calendar-cell-focus-shadow,
    $kendo-calendar-cell-selected-focus-shadow: $kendo-calendar-cell-selected-focus-shadow,
    $kendo-calendar-navigation-width: $kendo-calendar-navigation-width,
    $kendo-calendar-navigation-item-height: $kendo-calendar-navigation-item-height,
    $kendo-calendar-navigation-bg: $kendo-calendar-navigation-bg,
    $kendo-calendar-navigation-text: $kendo-calendar-navigation-text,
    $kendo-calendar-navigation-border: $kendo-calendar-navigation-border,
    $kendo-calendar-navigation-hover-text: $kendo-calendar-navigation-hover-text,
    $kendo-infinite-calendar-header-padding-x: $kendo-infinite-calendar-header-padding-x,
    $kendo-infinite-calendar-header-padding-y: $kendo-infinite-calendar-header-padding-y,
    $kendo-infinite-calendar-view-padding-x: $kendo-infinite-calendar-view-padding-x,
    $kendo-infinite-calendar-view-padding-y: $kendo-infinite-calendar-view-padding-y,
    $kendo-infinite-calendar-view-height: $kendo-infinite-calendar-view-height,
    $kendo-calendar-range-cell-border-radius: $kendo-calendar-range-cell-border-radius,
    $kendo-calendar-sm-font-size: $kendo-calendar-sm-font-size,
    $kendo-calendar-sm-line-height: $kendo-calendar-sm-line-height,
    $kendo-calendar-sm-cell-size: $kendo-calendar-sm-cell-size,
    $kendo-calendar-sm-cell-padding-x: $kendo-calendar-sm-cell-padding-x,
    $kendo-calendar-sm-cell-padding-y: $kendo-calendar-sm-cell-padding-y,
    $kendo-calendar-md-font-size: $kendo-calendar-md-font-size,
    $kendo-calendar-md-line-height: $kendo-calendar-md-line-height,
    $kendo-calendar-md-cell-size: $kendo-calendar-md-cell-size,
    $kendo-calendar-md-cell-padding-x: $kendo-calendar-md-cell-padding-x,
    $kendo-calendar-md-cell-padding-y: $kendo-calendar-md-cell-padding-y,
    $kendo-calendar-lg-font-size: $kendo-calendar-lg-font-size,
    $kendo-calendar-lg-line-height: $kendo-calendar-lg-line-height,
    $kendo-calendar-lg-cell-size: $kendo-calendar-lg-cell-size,
    $kendo-calendar-lg-cell-padding-x: $kendo-calendar-lg-cell-padding-x,
    $kendo-calendar-lg-cell-padding-y: $kendo-calendar-lg-cell-padding-y,
    $kendo-calendar-sizes: $kendo-calendar-sizes
);
