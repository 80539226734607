@use "sass:color";
@use "../core/_index.scss" as *;
@use "../core/functions/index.import.scss" as *;

// Chart

/// The first base series color and its light and dark shades.
/// @group charts
$kendo-series-a: if($kendo-enable-color-system, k-color( series-a ), $kendo-color-primary) !default;
$kendo-series-a-dark: if($kendo-enable-color-system, k-color( series-a-bold ), color.mix( black, $kendo-series-a, 25% )) !default;
$kendo-series-a-darker: if($kendo-enable-color-system, k-color( series-a-bolder ), color.mix( black, $kendo-series-a, 50% )) !default;
$kendo-series-a-light: if($kendo-enable-color-system, k-color( series-a-subtle ), color.mix( white, $kendo-series-a, 25% )) !default;
$kendo-series-a-lighter: if($kendo-enable-color-system, k-color( series-a-subtler ), color.mix( white, $kendo-series-a, 50% )) !default;

/// The second base series color and its light and dark shades.
/// @group charts
$kendo-series-b: if($kendo-enable-color-system, k-color( series-b ), $kendo-color-tertiary) !default;
$kendo-series-b-dark: if($kendo-enable-color-system, k-color( series-b-bold ), color.mix( black, $kendo-series-b, 25% )) !default;
$kendo-series-b-darker: if($kendo-enable-color-system, k-color( series-b-bolder ), color.mix( black, $kendo-series-b, 50% )) !default;
$kendo-series-b-light: if($kendo-enable-color-system, k-color( series-b-subtle ), color.mix( white, $kendo-series-b, 25% )) !default;
$kendo-series-b-lighter: if($kendo-enable-color-system, k-color( series-b-subtler ), color.mix( white, $kendo-series-b, 50% )) !default;

/// The third base series color and its light and dark shades.
/// @group charts
$kendo-series-c: if($kendo-enable-color-system, k-color( series-c ), #20c997) !default;
$kendo-series-c-dark: if($kendo-enable-color-system, k-color( series-c-bold ), color.mix( black, $kendo-series-c, 25% )) !default;
$kendo-series-c-darker: if($kendo-enable-color-system, k-color( series-c-bolder ), color.mix( black, $kendo-series-c, 50% )) !default;
$kendo-series-c-light: if($kendo-enable-color-system, k-color( series-c-subtle ), color.mix( white, $kendo-series-c, 25% )) !default;
$kendo-series-c-lighter: if($kendo-enable-color-system, k-color( series-c-subtler ), color.mix( white, $kendo-series-c, 50% )) !default;

/// The fourth base series color and its light and dark shades.
/// @group charts
$kendo-series-d: if($kendo-enable-color-system, k-color( series-d ), $kendo-color-success) !default;
$kendo-series-d-dark: if($kendo-enable-color-system, k-color( series-d-bold ), color.mix( black, $kendo-series-d, 25% )) !default;
$kendo-series-d-darker: if($kendo-enable-color-system, k-color( series-d-bolder ), color.mix( black, $kendo-series-d, 50% )) !default;
$kendo-series-d-light: if($kendo-enable-color-system, k-color( series-d-subtle ), color.mix( white, $kendo-series-d, 25% )) !default;
$kendo-series-d-lighter: if($kendo-enable-color-system, k-color( series-d-subtler ), color.mix( white, $kendo-series-d, 50% )) !default;

/// The fifth base series color and its light and dark shades.
/// @group charts
$kendo-series-e: if($kendo-enable-color-system, k-color( series-e ), $kendo-color-warning) !default;
$kendo-series-e-dark: if($kendo-enable-color-system, k-color( series-e-bold ), color.mix( black, $kendo-series-e, 25% )) !default;
$kendo-series-e-darker: if($kendo-enable-color-system, k-color( series-e-bolder ), color.mix( black, $kendo-series-e, 50% )) !default;
$kendo-series-e-light: if($kendo-enable-color-system, k-color( series-e-subtle ), color.mix( white, $kendo-series-e, 25% )) !default;
$kendo-series-e-lighter: if($kendo-enable-color-system, k-color( series-e-subtler ), color.mix( white, $kendo-series-e, 50% )) !default;

/// The sixth base series color and its light and dark shades.
/// @group charts
$kendo-series-f: if($kendo-enable-color-system, k-color( series-f ), $kendo-color-error) !default;
$kendo-series-f-dark: if($kendo-enable-color-system, k-color( series-f-bold ), color.mix( black, $kendo-series-f, 25% )) !default;
$kendo-series-f-darker: if($kendo-enable-color-system, k-color( series-f-bolder ), color.mix( black, $kendo-series-f, 50% )) !default;
$kendo-series-f-light: if($kendo-enable-color-system, k-color( series-f-subtle ), color.mix( white, $kendo-series-f, 25% )) !default;
$kendo-series-f-lighter: if($kendo-enable-color-system, k-color( series-f-subtler ), color.mix( white, $kendo-series-f, 50% )) !default;

/// The series colors in order:
/// base, light, dark, lighter, darker
/// @group charts
$kendo-series-1: $kendo-series-a !default;
$kendo-series-2: $kendo-series-b !default;
$kendo-series-3: $kendo-series-c !default;
$kendo-series-4: $kendo-series-d !default;
$kendo-series-5: $kendo-series-e !default;
$kendo-series-6: $kendo-series-f !default;
$kendo-series-7: $kendo-series-a-light !default;
$kendo-series-8: $kendo-series-b-light !default;
$kendo-series-9: $kendo-series-c-light !default;
$kendo-series-10: $kendo-series-d-light !default;
$kendo-series-11: $kendo-series-e-light !default;
$kendo-series-12: $kendo-series-f-light !default;
$kendo-series-13: $kendo-series-a-dark !default;
$kendo-series-14: $kendo-series-b-dark !default;
$kendo-series-15: $kendo-series-c-dark !default;
$kendo-series-16: $kendo-series-d-dark !default;
$kendo-series-17: $kendo-series-e-dark !default;
$kendo-series-18: $kendo-series-f-dark !default;
$kendo-series-19: $kendo-series-a-lighter !default;
$kendo-series-20: $kendo-series-b-lighter !default;
$kendo-series-21: $kendo-series-c-lighter !default;
$kendo-series-22: $kendo-series-d-lighter !default;
$kendo-series-23: $kendo-series-e-lighter !default;
$kendo-series-24: $kendo-series-f-lighter !default;
$kendo-series-25: $kendo-series-a-darker !default;
$kendo-series-26: $kendo-series-b-darker !default;
$kendo-series-27: $kendo-series-c-darker !default;
$kendo-series-28: $kendo-series-d-darker !default;
$kendo-series-29: $kendo-series-e-darker !default;
$kendo-series-30: $kendo-series-f-darker !default;

$kendo-chart-border-width: 0px !default;
$kendo-chart-font-family: var( --kendo-font-family, inherit ) !default;
$kendo-chart-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-chart-line-height: var( --kendo-line-height, normal )!default;
$kendo-chart-sm-font-size: 11px !default;
$kendo-chart-md-font-size: 12px !default;
$kendo-chart-lg-font-size: 16px !default;
$kendo-chart-tooltip-font-size: calc( #{$kendo-font-size} * .929) !default;
$kendo-chart-label-font-size: .857em !default;
$kendo-chart-title-font-size: 1.143em !default;
$kendo-chart-pane-title-font-size: $kendo-chart-label-font-size !default;
$kendo-chart-pane-title-font-weight: var( --kendo-font-weight-normal, normal ) !default;

/// The color of the Chart grid lines (major).
/// @group charts
$kendo-chart-major-lines: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 8%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .08 )) !default;

/// The color of the Chart grid lines (minor).
/// @group charts
$kendo-chart-minor-lines: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 4%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .04 )) !default;

$kendo-chart-inactive: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), rgba( $kendo-body-text, .5 )) !default;
$kendo-chart-area-opacity: .6 !default;
$kendo-chart-area-inactive-opacity: .1 !default;
$kendo-chart-line-inactive-opacity: .3 !default;

$kendo-chart-tooltip-padding-x: k-spacing(2) !default;
$kendo-chart-tooltip-padding-y: k-spacing(1) !default;

$kendo-chart-tooltip-transition: left 300ms cubic-bezier(0, .33, .19, 1), top 300ms cubic-bezier(0, .33, .19, 1) !default;

$kendo-chart-bg: $kendo-component-bg !default;
$kendo-chart-text: $kendo-component-text !default;
$kendo-chart-border: $kendo-component-border !default;

$kendo-chart-crosshair-background: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .5 )) !default;
$kendo-chart-crosshair-shared-tooltip-color: $kendo-chart-text !default;
$kendo-chart-crosshair-shared-tooltip-background: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-chart-bg, 1 )) !default;
$kendo-chart-crosshair-shared-tooltip-border: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 8%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .08 )) !default;

$kendo-chart-notes-background: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .5 )) !default;
$kendo-chart-notes-border: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .5 )) !default;
$kendo-chart-notes-lines: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .5 )) !default;

$kendo-chart-error-bars-background: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .5 )) !default;

$kendo-chart-selection-handle-size: 22px !default;
$kendo-chart-selection-border-color: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 8%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .08 )) !default;
$kendo-chart-selection-shadow: inset 0 1px 7px if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 15%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .15 )) !default;

$kendo-chart-computed-title-height: 0px !default;

// TreeMap
$kendo-treemap-font-family: var( --kendo-font-family, inherit ) !default;
$kendo-treemap-font-size: var( --kendo-font-size, inherit )  !default;
$kendo-treemap-line-height: var( --kendo-line-height, normal ) !default;

$kendo-treemap-padding-x: k-spacing(2) !default;
$kendo-treemap-padding-y: k-spacing(1) !default;

// Chart Overlay
$kendo-chart-overlay-bg: $kendo-base-bg !default;

// No Data
$kendo-chart-no-data-font-size: var( --kendo-font-size-lg, inherit ) !default;
$kendo-chart-no-data-font-weight: var( --kendo-font-weight, normal ) !default;
$kendo-chart-no-data-padding-y: k-spacing( 25 ) !default;


@forward "@progress/kendo-theme-default/scss/dataviz/_variables.scss" with (
    $kendo-series-a: $kendo-series-a,
    $kendo-series-a-dark: $kendo-series-a-dark,
    $kendo-series-a-darker: $kendo-series-a-darker,
    $kendo-series-a-light: $kendo-series-a-light,
    $kendo-series-a-lighter: $kendo-series-a-lighter,
    $kendo-series-b: $kendo-series-b,
    $kendo-series-b-dark: $kendo-series-b-dark,
    $kendo-series-b-darker: $kendo-series-b-darker,
    $kendo-series-b-light: $kendo-series-b-light,
    $kendo-series-b-lighter: $kendo-series-b-lighter,
    $kendo-series-c: $kendo-series-c,
    $kendo-series-c-dark: $kendo-series-c-dark,
    $kendo-series-c-darker: $kendo-series-c-darker,
    $kendo-series-c-light: $kendo-series-c-light,
    $kendo-series-c-lighter: $kendo-series-c-lighter,
    $kendo-series-d: $kendo-series-d,
    $kendo-series-d-dark: $kendo-series-d-dark,
    $kendo-series-d-darker: $kendo-series-d-darker,
    $kendo-series-d-light: $kendo-series-d-light,
    $kendo-series-d-lighter: $kendo-series-d-lighter,
    $kendo-series-e: $kendo-series-e,
    $kendo-series-e-dark: $kendo-series-e-dark,
    $kendo-series-e-darker: $kendo-series-e-darker,
    $kendo-series-e-light: $kendo-series-e-light,
    $kendo-series-e-lighter: $kendo-series-e-lighter,
    $kendo-series-f: $kendo-series-f,
    $kendo-series-f-dark: $kendo-series-f-dark,
    $kendo-series-f-darker: $kendo-series-f-darker,
    $kendo-series-f-light: $kendo-series-f-light,
    $kendo-series-f-lighter: $kendo-series-f-lighter,
    $kendo-series-1: $kendo-series-1,
    $kendo-series-2: $kendo-series-2,
    $kendo-series-3: $kendo-series-3,
    $kendo-series-4: $kendo-series-4,
    $kendo-series-5: $kendo-series-5,
    $kendo-series-6: $kendo-series-6,
    $kendo-series-7: $kendo-series-7,
    $kendo-series-8: $kendo-series-8,
    $kendo-series-9: $kendo-series-9,
    $kendo-series-10: $kendo-series-10,
    $kendo-series-11: $kendo-series-11,
    $kendo-series-12: $kendo-series-12,
    $kendo-series-13: $kendo-series-13,
    $kendo-series-14: $kendo-series-14,
    $kendo-series-15: $kendo-series-15,
    $kendo-series-16: $kendo-series-16,
    $kendo-series-17: $kendo-series-17,
    $kendo-series-18: $kendo-series-18,
    $kendo-series-19: $kendo-series-19,
    $kendo-series-20: $kendo-series-20,
    $kendo-series-21: $kendo-series-21,
    $kendo-series-22: $kendo-series-22,
    $kendo-series-23: $kendo-series-23,
    $kendo-series-24: $kendo-series-24,
    $kendo-series-25: $kendo-series-25,
    $kendo-series-26: $kendo-series-26,
    $kendo-series-27: $kendo-series-27,
    $kendo-series-28: $kendo-series-28,
    $kendo-series-29: $kendo-series-29,
    $kendo-series-30: $kendo-series-30,
    $kendo-chart-border-width: $kendo-chart-border-width,
    $kendo-chart-font-family: $kendo-chart-font-family,
    $kendo-chart-font-size: $kendo-chart-font-size,
    $kendo-chart-line-height: $kendo-chart-line-height,
    $kendo-chart-sm-font-size: $kendo-chart-sm-font-size,
    $kendo-chart-md-font-size: $kendo-chart-md-font-size,
    $kendo-chart-lg-font-size: $kendo-chart-lg-font-size,
    $kendo-chart-tooltip-font-size: $kendo-chart-tooltip-font-size,
    $kendo-chart-label-font-size: $kendo-chart-label-font-size,
    $kendo-chart-title-font-size: $kendo-chart-title-font-size,
    $kendo-chart-pane-title-font-size: $kendo-chart-pane-title-font-size,
    $kendo-chart-pane-title-font-weight: $kendo-chart-pane-title-font-weight,
    $kendo-chart-major-lines: $kendo-chart-major-lines,
    $kendo-chart-minor-lines: $kendo-chart-minor-lines,
    $kendo-chart-inactive: $kendo-chart-inactive,
    $kendo-chart-area-opacity: $kendo-chart-area-opacity,
    $kendo-chart-area-inactive-opacity: $kendo-chart-area-inactive-opacity,
    $kendo-chart-line-inactive-opacity: $kendo-chart-line-inactive-opacity,
    $kendo-chart-tooltip-padding-x: $kendo-chart-tooltip-padding-x,
    $kendo-chart-tooltip-padding-y: $kendo-chart-tooltip-padding-y,
    $kendo-chart-tooltip-transition: $kendo-chart-tooltip-transition,
    $kendo-chart-bg: $kendo-chart-bg,
    $kendo-chart-text: $kendo-chart-text,
    $kendo-chart-border: $kendo-chart-border,
    $kendo-chart-crosshair-background: $kendo-chart-crosshair-background,
    $kendo-chart-crosshair-shared-tooltip-color: $kendo-chart-crosshair-shared-tooltip-color,
    $kendo-chart-crosshair-shared-tooltip-background: $kendo-chart-crosshair-shared-tooltip-background,
    $kendo-chart-crosshair-shared-tooltip-border: $kendo-chart-crosshair-shared-tooltip-border,
    $kendo-chart-notes-background: $kendo-chart-notes-background,
    $kendo-chart-notes-border: $kendo-chart-notes-border,
    $kendo-chart-notes-lines: $kendo-chart-notes-lines,
    $kendo-chart-error-bars-background: $kendo-chart-error-bars-background,
    $kendo-chart-selection-handle-size: $kendo-chart-selection-handle-size,
    $kendo-chart-selection-border-color: $kendo-chart-selection-border-color,
    $kendo-chart-selection-shadow: $kendo-chart-selection-shadow,
    $kendo-chart-computed-title-height: $kendo-chart-computed-title-height,
    $kendo-treemap-font-family: $kendo-treemap-font-family,
    $kendo-treemap-font-size: $kendo-treemap-font-size,
    $kendo-treemap-line-height: $kendo-treemap-line-height,
    $kendo-treemap-padding-x: $kendo-treemap-padding-x,
    $kendo-treemap-padding-y: $kendo-treemap-padding-y,
    $kendo-chart-overlay-bg: $kendo-chart-overlay-bg,
    $kendo-chart-no-data-font-size: $kendo-chart-no-data-font-size,
    $kendo-chart-no-data-font-weight: $kendo-chart-no-data-font-weight,
    $kendo-chart-no-data-padding-y: $kendo-chart-no-data-padding-y
);
